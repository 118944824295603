import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FolderIcon from '@material-ui/icons/Folder';
import { MovieRounded, SubdirectoryArrowLeft, AddCircleOutline, CreateNewFolder } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from "firebase";
import CustomizedStationZoneData from "./CustomizedStationZoneData"
import RefreshIcon from '@material-ui/icons/Refresh';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CustomizedSubZoneTable from "./CustomizedSubZoneTable"
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows } = this.props;
    
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false }
              >
                <Tooltip
                  title="Sort"
                  disableTouchListener={row.sortable ? false : true }
                  disableFocusListener={row.sortable ? false : true }
                  disableHoverListener={row.sortable ? false : true }
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={(orderBy === row.id && row.sortable) ? true : false }
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, onMoveAllClick, onDeleteAllClick, movefile, onAddFolderClick } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          movefile ? <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Move">
                <IconButton aria-label="Move" onClick={onMoveAllClick}>
                <SubdirectoryArrowLeft />
                </IconButton>
            </Tooltip>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div> : 
            <div style={{ display: 'flex', alignItems: 'center' }}>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div>
        ) : (
          <Tooltip title="Add Zone">
            <IconButton aria-label="Add Zone" onClick={onAddFolderClick}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actionButtons: {
    display: 'flex'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: 10,
    flex: 1,
  },
  tab: {
    borderLeft: '1px solid #e8e8e8',
  },
});

class EnhancedTable extends React.Component {

  state = {
    order: 'desc',
    orderBy: this.props.orderBy,
    selected: [],
    selectedId: '',
    page: 0,
    rowsPerPage: 20,
    openDialog: false,
    dialogTitle: 'No Title',
    zoneId: '',
    folderName: '',
    dialogOption: '',
    selectedFileName: '',
    stationData: [],
    zoneCollection: [],
    addZoneName: '',
    selectedTableCell: '',
    fullScreenDialog: false,
    value: 0,
    selectedSubZoneName: '',
    allData:[],
      filtertxt: '',
      stationCollection: []
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.data.map(n => n.id) })
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  onFolderClick = (id, name) => {

    this.state.filtertxt = '';
    let ref = app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station');

    ref.on('value', snapshot => {
      const stationData = [];

      if(firebase.auth().currentUser.email == "taysockchoo@apmasia.com.sg" 
          || firebase.auth().currentUser.email == "kymlow@apmasia.com.sg"
          || firebase.auth().currentUser.email == "zhouquan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "yongpohyi@apmasia.com.sg"
          || firebase.auth().currentUser.email == "nurulkhairiah@apmasia.com.sg"){

            snapshot.forEach(childSnapshot => {
              const item = childSnapshot.val();
              item.key = childSnapshot.key;
              
              if(item.zone === id)
              {
                if(item.key === '-M1f7821il8GQiWfwq2O' || item.key === '-M1f79nU5ZbYYZVjc86D' 
                || item.key === '-MNXZ1ATJFRg82UeLgF5' || item.key === '-MNXWcVETq9_W0XozRCC' 
                || item.key === '-MNXRFTGXZwi3PE7BItY' || item.key === '-MNXP97XrPWODjcOziZ5' )
                {
                  stationData.push({ id:item.key, name: item.name, campaign: item.campaign, created: item.created, connected: item.connected, updated: item.updated, used: item.used, zone: item.zone, app_version: item.app_version ? item.app_version: "1.0.0", is_playing: item.is_playing? true : false
                  , push_date: item.push_date ? item.push_date : "0000000000",
                  sim_serial: item.sim_serial ? item.sim_serial : "0",
                  total_file_in_app: item.total_file_in_app ? item.total_file_in_app : "null"
                  ,sub_zone: item.sub_zone
                  });
                }
              }
              
            });
      }
      else
      {
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          if(item.zone === id)
          {
              stationData.push({ id:item.key, name: item.name, campaign: item.campaign, created: item.created, connected: item.connected, updated: item.updated, used: item.used, zone: item.zone, app_version: item.app_version ? item.app_version: "1.0.0", is_playing: item.is_playing? true : false
              , push_date: item.push_date ? item.push_date : "0000000000",
              sim_serial: item.sim_serial ? item.sim_serial : "0",
              total_file_in_app: item.total_file_in_app ? item.total_file_in_app : "null"
              ,sub_zone: item.sub_zone
              });
            
          }
          
        });
      }
      

      var filterData = stationData.filter(item => item.name.toLowerCase().includes(this.state.filtertxt));

      this.setState({ stationData: filterData, allData: stationData });
    });

    let folderRef = app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station_zone');

    folderRef.on('value', snapshot => {
      const zoneCollection = [];

      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;
        
        zoneCollection.push({ id:item.key, name: item.name, created: item.created, total: item.total });
        
      });

      this.setState({ zoneCollection });
    });

    this.setState({
        openDialog: true,
        dialogTitle: name,
        zoneId: id,
        folderName: name,
        dialogOption: 'zoneSelect',
        fullScreenDialog: true,
        value: 0
    });
  }

  onAddFolderClick = () => {
    this.setState({
        openDialog: true,
        dialogTitle: "Add Zone",
        dialogOption: 'addZone',
        addZoneName: '',
        fullScreenDialog: false
    });
  }

  onEditClick = (id, name) => {
    
    this.setState({
        openDialog: true,
        dialogTitle: "Edit Zone",
        selectedId: id,
        dialogOption: 'edit',
        selectedFileName: name,
        selectedFolder: 'default',
        fullScreenDialog: false
    });
  }

  onDeleteClick = (id) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Remove Zone",
        selectedId: id,
        dialogOption: 'delete',
        fullScreenDialog: false
    });
  }

  onDeleteAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'deleteAll',
        dialogTitle: "Remove Zone",
        fullScreenDialog: false
    });
  }

  onMoveAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'moveAll',
        dialogTitle: "Move file",
        selectedFolder: 'default',
        fullScreenDialog: false
    });
  }

  onCreateSubZoneClick = (id) => {
    this.setState({
      openDialog: true,
      dialogTitle: "Create Subzone",
      selectedId: id,
      dialogOption: 'createSubZone',
      fullScreenDialog: false,
      selectedSubZoneName: ''
    });
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  _handleEditFileNameChange = e => {
    this.setState({
        selectedFileName: e.target.value
    });
  }

  handleSelectionChange = event => {
    this.setState({ selectedFolder: event.target.value });
  };

  handleEditFile = () => {
    app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.state.selectedId).update({ name: this.state.selectedFileName });

    this.setState({
        openDialog: false,
        selectedFolder: 'default',
        selectedFileName: '',
        selected: []
    });
  }

  handleRemoveFile = () => {

    let ref = app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station');

      ref.once('value', snapshot => {

        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          
          if(item.zone === this.state.selectedId){
              
              app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station').child(childSnapshot.key).remove()
              app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('push_station').child(childSnapshot.key).remove()
          }
          
        });
    });

    app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref("station_zone").child(this.state.selectedId).remove()

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
    
  }

  handleRemoveAllFile = () => {

    this.state.selected.forEach(items => {

      let ref = app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station');

      ref.once('value', snapshot => {

        snapshot.forEach(childSnapshot => {
            const item = childSnapshot.val();
            
            if(item.zone === items){
                
                app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station').child(childSnapshot.key).remove()
                app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('push_station').child(childSnapshot.key).remove()

            }
            
            });
        });

        app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref("station_zone").child(items).remove()
    })

    this.setState({
      openDialog: false,
      selected: []
    });
  }

  handleMoveAllFile = () => {
    
    if(this.state.selectedFolder !== 'default'){
      this.state.selected.forEach(items => {
        app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref().child('/media/' + items).update({ folder: this.state.selectedFolder });
  
        app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref().child('/media_folder/' + this.state.selectedFolder).child('total').transaction( total => {
          
          return total + 1;
        })
      })
    }

    this.setState({
        openDialog: false,
        selectedFolder: 'default',
        selected: []
    });

  }

  _handleZoneNameChange = e => {
    this.setState({
        addZoneName: e.target.value
    });
  }

  handleAddZone = async event => {

    var postData = {
        name: this.state.addZoneName,
        created: firebase.database.ServerValue.TIMESTAMP,
        total: 0
    };

    // Get a key for a new Post.
    var newPostKey = app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref().child('station_zone').push().key;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/station_zone/' + newPostKey] = postData;

    app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref().update(updates);

    this.setState({
        openDialog: false,
        addZoneName: '',
        selected: []
    });
  }

  handleTabsChange = (event, value) => {
    this.searchReset();
    this.setState({ value });
  };

  search (e) {
    var filterData = this.state.allData.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

    this.setState({ stationData: filterData, filtertxt:e.target.value.toLowerCase()  });
  }

  searchReset(){
    var filterData = this.state.allData.filter(item => item.name.toLowerCase().includes(""));

    this.setState({ stationData: filterData, filtertxt:""  });
  }

  renderDialog = () => {

    if(this.state.dialogOption === 'zoneSelect'){

      const subZoneCollection = []
      let refSubZone = app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station_zone/'+this.state.zoneId+"/sub_zone");

      refSubZone.on('value', subZoneSnapshot => {

        subZoneSnapshot.forEach(subZoneChildSnapshot => {
          const item = subZoneChildSnapshot.val();
          item.key = subZoneChildSnapshot.key;
          
          subZoneCollection.push({id:item.key, created: item.created, total: item.total, name: item.name });
          
        });

      });

        return(
            <DialogContent style={{paddingLeft:0, paddingRight:0}}>
              <AppBar className={this.props.classes.AppBar} position="static" color="default">
                <Tabs
                  value={this.state.value}
                  onChange={this.handleTabsChange}
                  indicatorColor="primary"
                  textColor="primary"

                >
                  <Tab className={this.props.classes.tab} disableRipple label="Station" />
                  <Tab className={this.props.classes.tab} disableRipple label="SubZone" />
                </Tabs>
              </AppBar>
              {this.state.value === 0 && 
              <TabContainer >
                  <CustomizedStationZoneData
                    rows={this.props.isMobile ? [
                      { id: 'name', numeric: false, disablePadding: true, label: 'Station Name', sortable: true },
                      { id: 'connected', numeric: false, disablePadding: false, label: 'Status', sortable: true },
                      { id: 'app_version', numeric: false, disablePadding: false, label: 'App Version', sortable: true }
                    ] : [
                      { id: 'name', numeric: false, disablePadding: true, label: 'Station Name', sortable: true },
                      { id: 'connected', numeric: false, disablePadding: false, label: 'Status', sortable: true },
                      { id: 'is_playing', numeric: false, disablePadding: false, label: 'Playing', sortable: true },
                      { id: 'app_version', numeric: false, disablePadding: false, label: 'App Version', sortable: true },
                      { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
                    ]} 
                    data={this.state.stationData}
                    orderBy={'name'}
                    zoneCollection={this.state.zoneCollection}
                    preview={true}
                    movefile={true}
                    dataName='station'
                    selectedZone={this.state.zoneId}
                    isMobile={this.props.isMobile}
                    search={(e) => {this.search(e)}}
                    searchReset = {() => {this.searchReset()}}
                    />
                </TabContainer> }
                {this.state.value === 1 && 
                <TabContainer>
                    <CustomizedSubZoneTable 
                      rows={this.props.isMobile ? [
                        { id: 'name', numeric: false, disablePadding: true, label: 'Zone Name', sortable: true },
                        { id: 'total', numeric: false, disablePadding: false, label: 'Total Station', sortable: true }
                      ] : [
                        { id: 'name', numeric: false, disablePadding: true, label: 'Zone Name', sortable: true },
                        { id: 'total', numeric: false, disablePadding: false, label: 'Total Station', sortable: true },
                        { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
                      ]} 
                      data={subZoneCollection}
                      orderBy={'created'}
                      zoneCollection={subZoneCollection}
                      movefile={false}
                      zoneId={this.state.zoneId}
                      dataName={'station_zone/'+ this.state.zoneId+"/sub_zone"}
                      isMobile={this.props.isMobile} />
                </TabContainer> }
            </DialogContent>
        )
    } 

    else if(this.state.dialogOption === 'edit'){
        return(
            <div>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    label="File Name"
                    fullWidth
                    value={this.state.selectedFileName} 
                    onChange={this._handleEditFileNameChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleEditFile} color="primary">
                Submit
                </Button>
                <Button onClick={this.handleClose} color="default">
                Cancel
                </Button>
            </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'delete'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these folder?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'deleteAll'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these folder?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveAllFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'refresh'){
      return(
          <div>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Are you sure you want to refresh this zone?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleRefreshZone} color="default">
                  Yes
                  </Button>
                  <Button color="primary" onClick={this.handleClose}>
                  No
                  </Button>
              </DialogActions>
          </div>
      )
    }

    else if(this.state.dialogOption === 'moveAll'){
        return(
            <div>
            <DialogContent style={{ width: 200}}>
                <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off">
                    <InputLabel
                    ref={ref => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-age-simple"
                    >
                    Folder
                    </InputLabel>
                    <Select
                    value={this.state.selectedFolder}
                    onChange={this.handleSelectionChange}
                    input={
                        <OutlinedInput
                        labelWidth={100}
                        name="folder"
                        id="outlined-age-simple"
                        />
                    }
                    >
                    
                    <MenuItem value="default">
                        <em>Default</em>
                    </MenuItem>
                    {this.props.zoneCollection.map(n => {
                        return(
                        <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                        )
                    })}

                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleMoveAllFile} color="primary">
                Yes
                </Button>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                No
                </Button>
            </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'createSubZone'){
      return(
          <div>
          <DialogContent>
              <TextField
                  margin="dense"
                  id="name"
                  label="Subzone Name"
                  fullWidth
                  value={this.state.selectedSubZoneName} 
                  onChange={this._handleEditSubZoneNameChange}
              />
          </DialogContent>
          <DialogActions>
              <Button onClick={this.handleCreateSubZone} color="primary">
              Submit
              </Button>
              <Button onClick={this.handleClose} color="default">
              Cancel
              </Button>
          </DialogActions>
          </div>
      )
    }
      
    else if(this.state.dialogOption === 'addZone'){
      return(
        <div>
          <DialogContent>
            <FormControl fullWidth variant="outlined" noValidate autoComplete="off" onSubmit={this.handleAddZone}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Zone Name"
                fullWidth
                value={this.state.addZoneName} 
                onChange={this._handleZoneNameChange}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAddZone} color="primary">
              Add
            </Button>
            <Button onClick={this.handleClose} color="default">
              Cancel
            </Button>
          </DialogActions>
        </div>
      )
    }
  }

  _handleEditSubZoneNameChange = e => {
    this.setState({
        selectedSubZoneName: e.target.value
    });
  }

  handleCreateSubZone = () => {
    var postData = {
      name: this.state.selectedSubZoneName,
      created: firebase.database.ServerValue.TIMESTAMP,
      total: 0
    };
    // Get a key for a new Post.
    var newPostKey = app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref().child('/station_zone/' + this.state.selectedId +'/sub_zone').push().key;;

    // Write the new post's data simultaneously in the posts list and the user's post list.
    var updates = {};
    updates['/station_zone/' + this.state.selectedId +'/sub_zone/' + newPostKey] = postData;

    app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref().update(updates);

    this.setState({
      openDialog: false,
      selectedSubZoneName: '',
      selected: []
  });
  }

  onRefreshClick = (id) =>{
    this.setState({
      openDialog: true,
      dialogTitle: "Refresh Zone",
      selectedId: id,
      dialogOption: 'refresh',
      fullScreenDialog: false
    });
  }

  handleRefreshZone = () => {
    app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station').once('value', snapshot => { 
      snapshot.forEach(childSnapshot => { 
        if(childSnapshot.val().zone === this.state.selectedId){
          app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('push_station/' + childSnapshot.key).update({ push: true, message: 'push' })
          app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station/' + childSnapshot.key ).update({ push_date: firebase.database.ServerValue.TIMESTAMP })
        }
      })
      
    })

    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
  }

  handleToggle = (event, id) => { 

    if(id === this.state.selectedTableCell){
      this.setState({ selectedTableCell: ''})
    } else {
      this.setState({ selectedTableCell: id})
    }
    
  }

  componentWillMount(){

    let ref = app.database(directoryMode[0].mode ? "https://suntec-f5bcf-44a5b.firebaseio.com/":undefined ).ref('station');

    ref.on('value', snapshot => {
      const stationCollection = [];

      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        item.key = childSnapshot.key;

        stationCollection.push({ id:item.key, name: item.name, campaign: item.campaign, created: item.created, connected: item.connected, updated: item.updated, used: item.used, zone: item.zone, app_version: item.app_version ? item.app_version: "1.0.0", is_playing: item.is_playing? true : false
        , push_date: item.push_date ? item.push_date : "0000000000",
        sim_serial: item.sim_serial ? item.sim_serial : "0",
        total_file_in_app: item.total_file_in_app ? item.total_file_in_app : "null"  });

        
      });

      this.setState({ stationCollection });
    });

    
  }

  
  render() {
    const { classes, rows, data } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
            maxWidth='xl'
            fullScreen={this.state.fullScreenDialog}
          >
          {this.state.fullScreenDialog ?<div></div> : <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.state.dialogTitle}
          </DialogTitle>}

          {this.state.fullScreenDialog ? 
            <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography style={{ color:'white', paddingLeft: 10 }} variant="h6" >
                {this.state.dialogTitle}
              </Typography>
            </Toolbar>
          </AppBar> : <div></div>}

          {this.renderDialog()}
        </Dialog>
        <EnhancedTableToolbar numSelected={selected.length} onDeleteAllClick={this.onDeleteAllClick} onMoveAllClick={this.onMoveAllClick} movefile={this.props.movefile} onAddFolderClick={this.onAddFolderClick}/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
            />
            
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);

                  const station_content = []

                  this.state.stationCollection.map(val => { 
                    if(val.zone === n.id){
                      station_content.push(val)
                    }
                  })

                  return (
                    [<TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell onClick={event => this.handleClick(event, n.id)} padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>

                      <TableCell onClick={event => this.handleToggle(event, n.id)} align="left" padding="none">{n.name}</TableCell>

                      <TableCell onClick={event => this.handleToggle(event, n.id)} align="left" >{station_content.length}</TableCell>

                      {!this.props.isMobile ? <TableCell align="left" padding="none" >
                      <div className={classes.actionButtons}>
                      { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ?<Tooltip title="Refresh Zone">
                     <IconButton color={"default"}  onClick={() => this.onRefreshClick(n.id) }>
                              <RefreshIcon fontSize="small" />
                          </IconButton>
                          </Tooltip> : null }
                          <Tooltip title="Enter Zone">
                          <IconButton color={"primary"} onClick={() => this.onFolderClick(n.id, n.name) }>
                              <FolderIcon fontSize="small" />
                          </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                          <IconButton onClick={() => this.onEditClick(n.id, n.name) }>
                              <EditIcon fontSize="small" />
                          </IconButton>
                          </Tooltip>
                          { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ?  <Tooltip title="Delete">
                          <IconButton onClick={() => this.onDeleteClick(n.id) }>
                              <DeleteIcon fontSize="small" />
                          </IconButton>
                          </Tooltip>: null}
                        </div>
                        </TableCell> : ''}
                      
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Id</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.id}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Created By</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.created)}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Subzone</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.sub_zone ? Object.keys(n.sub_zone).length : 0}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id && this.props.isMobile ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell padding={'default'} colSpan={12}>
                        <div className={classes.actionButtons}>
                        { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ?  <IconButton color={"default"}  onClick={() => this.onRefreshClick(n.id) }>
                              <RefreshIcon fontSize="small" />
                          </IconButton> : null}
                          
                          <IconButton color={"primary"} onClick={() => this.onFolderClick(n.id, n.name) }>
                              <FolderIcon fontSize="small" />
                          </IconButton>
                          <IconButton onClick={() => this.onEditClick(n.id, n.name) }>
                              <EditIcon fontSize="small" />
                          </IconButton>
                          { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ?  <IconButton onClick={() => this.onDeleteClick(n.id) }>
                              <DeleteIcon fontSize="small" />
                          </IconButton> : null }
                        </div>
                      </TableCell>
                    </TableRow>]
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[20, 50, 200]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);