import React, { Component } from 'react';
import CustomizedExpansionPanel from '../components/CustomizedExpansionPanel'
import CustomizedDropzone from '../components/CustomizedDropzone'
import CustomizedTable from '../components/CustomizedTable'
import CustomizedFolderTable from '../components/CustomizedFolderTable'
import firebase from "firebase";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  AppBar: {
    boxShadow: 'none',
    borderBottom: '1px solid #e8e8e8',
  },
  tab: {
    borderLeft: '1px solid #e8e8e8',
  },
});

class Contents extends Component {

  state = {
    mediaData:[],
    folderCollection:[],
    value: 0,
    isMobile: false
  }

  componentWillMount(){
    

    if(directoryMode[0].mode){
      let ref = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media');

      ref.on('value', snapshot => {
        const mediaData = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          if(item.folder === 'default')
          {
            mediaData.push({ noted:item.noted, id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder, user: item.user });
          }
          
        });
  
        this.setState({ mediaData });
      });
  
      let folderRef = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media_folder');
  
      folderRef.on('value', snapshot => {
        const folderCollection = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          folderCollection.push({ id:item.key, name: item.name, created: item.created, total: item.total, sub_folder: item.sub_folder });
          
        });
  
        this.setState({ folderCollection });
      });
    }
    else{
      let ref = app.database().ref('media');

      ref.on('value', snapshot => {
        const mediaData = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          if(item.folder === 'default')
          {
            mediaData.push({ noted:item.noted, id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder, user: item.user });
          }
          
        });
  
        this.setState({ mediaData });
      });
  
      let folderRef = app.database().ref('media_folder');
  
      folderRef.on('value', snapshot => {
        const folderCollection = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          folderCollection.push({ id:item.key, name: item.name, created: item.created, total: item.total, sub_folder: item.sub_folder });
          
        });
  
        this.setState({ folderCollection });
      });
    }
    

    const windowSize = window.innerWidth;
    if(windowSize < 800){
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }

    window.addEventListener("resize", this.handleResize );
  }

  handleResize  = () => {
    const windowSize = window.innerWidth;
    if(windowSize < 800){
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  renderMediaContent(){

    return <CustomizedTable 
      rows={this.state.isMobile ? [
        { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
        { id: 'imageUrl', numeric: false, disablePadding: false, label: 'File Thumbnail', sortable: true, thumbnail: true },
      ] : [
        { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
        { id: 'imageUrl', numeric: false, disablePadding: false, label: 'File Thumbnail', sortable: true, thumbnail: true },
        { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
      ]} 
      data={this.state.mediaData}
      orderBy={'created'}
      folderCollection={this.state.folderCollection}
      preview={true}
      movefile={true}
      isMobile={this.state.isMobile}
      dataName='media'/>
  }

  renderFoldersContent(){
    return <CustomizedFolderTable 
      rows={this.state.isMobile ? [
        { id: 'name', numeric: false, disablePadding: true, label: 'Folder Name', sortable: true },
        { id: 'total', numeric: false, disablePadding: false, label: 'Total Files', sortable: true }
      ] : [
        { id: 'name', numeric: false, disablePadding: true, label: 'Folder Name', sortable: true },
        { id: 'total', numeric: false, disablePadding: false, label: 'Total Files', sortable: true },
        { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
      ]} 
      data={this.state.folderCollection}
      orderBy={'created'}
      folderCollection={this.state.folderCollection}
      movefile={false}
      isMobile={this.state.isMobile}
      dataName='media_folder'/>
  }

  handleTabsChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;

    return (
        <div style={{ padding: 0 }}>
          <CustomizedDropzone  folder="default" sub_folder={null} />
          <AppBar className={classes.AppBar} position="static" color="default">
            <Tabs
              value={this.state.value}
              onChange={this.handleTabsChange}
              indicatorColor="primary"
              textColor="primary"

            >
              <Tab className={classes.tab} disableRipple label="Media" />
              <Tab className={classes.tab} disableRipple label="Folder" />
            </Tabs>
          </AppBar>
          {this.state.value === 0 && 
          <TabContainer >
            {this.renderMediaContent()}
          </TabContainer>}
          {this.state.value === 1 && 
          <TabContainer >
            {this.renderFoldersContent()}
          </TabContainer>}
         
        </div>
    );
  }
}

Contents.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contents);