import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FolderIcon from '@material-ui/icons/Folder';
import { MovieRounded, SubdirectoryArrowLeft, AddCircleOutline, CreateNewFolder } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from "firebase";
import CustomizedFolderData from "./CustomizedFolderData"
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CustomizedSubFolderTable from "./CustomizedSubFolderTable"
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";

function TabContainer({ children, dir }) {
  return (
    <div dir={dir}>
      {children}
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, rows } = this.props;
    
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false }
              >
                <Tooltip
                  title="Sort"
                  disableTouchListener={row.sortable ? false : true }
                  disableFocusListener={row.sortable ? false : true }
                  disableHoverListener={row.sortable ? false : true }
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={(orderBy === row.id && row.sortable) ? true : false }
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, onMoveAllClick, onDeleteAllClick, movefile, onAddFolderClick } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          movefile ? <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Move">
                <IconButton aria-label="Move" onClick={onMoveAllClick}>
                <SubdirectoryArrowLeft />
                </IconButton>
            </Tooltip>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div> : 
            <div style={{ display: 'flex', alignItems: 'center' }}>
            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={onDeleteAllClick}>
                <DeleteIcon />
                </IconButton>
            </Tooltip> : null }
            </div>
        ) : (
          <Tooltip title="Add Folder">
            <IconButton aria-label="Add Folder" onClick={onAddFolderClick}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  actionButtons: {
    display: 'flex'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: 10,
    flex: 1,
  },
  tab: {
    borderLeft: '1px solid #e8e8e8',
  },
});

class EnhancedTable extends React.Component {

  state = {
    order: 'desc',
    orderBy: this.props.orderBy,
    selected: [],
    selectedId: '',
    page: 0,
    rowsPerPage: 20,
    openDialog: false,
    dialogTitle: 'No Title',
    folderId: '',
    folderName: '',
    dialogOption: '',
    selectedFileName: '',
    mediaData: [],
    allMediaData: [],
    folderCollection: [],
    addFolderName: '',
    selectedTableCell: '',
    fullScreenDialog: false,
    campaignCollection: [],
    campaignAdsCollection: [],
    mediaCollection: [],
    value:0,
    selectedSubFolderName: '',
    filtertxt: '',
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState({ selected: this.props.data.map(n => n.id) })
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  onFolderClick = (id, name) => {

    this.state.filtertxt = '';

    if(directoryMode[0].mode){
      let ref = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media');

      ref.on('value', snapshot => {
        const mediaData = [];
        const allMediaData = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          if(item.folder === id )
          {
            if(item.sub_folder === 'default' || !item.sub_folder){
              mediaData.push({ id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder, user: item.user, noted: item.noted });
              allMediaData.push({ id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder, user: item.user, noted: item.noted });
            }
           
          }
          
        });
  
        var filterData = allMediaData.filter(item => item.name.toLowerCase().includes(this.state.filtertxt));
  
        this.setState({ mediaData: filterData, allMediaData });
      });
  
      let folderRef = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media_folder');
  
      folderRef.on('value', snapshot => {
        const folderCollection = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          folderCollection.push({ id:item.key, name: item.name, created: item.created, total: item.total });
          
        });
  
        this.setState({ folderCollection });
      });
    }

    else{
      let ref = app.database().ref('media');

      ref.on('value', snapshot => {
        const mediaData = [];
        const allMediaData = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          if(item.folder === id )
          {
            if(item.sub_folder === 'default' || !item.sub_folder){
              mediaData.push({ id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder, user: item.user, noted: item.noted });
              allMediaData.push({ id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder, user: item.user, noted: item.noted });
            }
           
          }
          
        });
  
        var filterData = allMediaData.filter(item => item.name.toLowerCase().includes(this.state.filtertxt));
  
        this.setState({ mediaData: filterData, allMediaData });
      });
  
      let folderRef = app.database().ref('media_folder');
  
      folderRef.on('value', snapshot => {
        const folderCollection = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          folderCollection.push({ id:item.key, name: item.name, created: item.created, total: item.total });
          
        });
  
        this.setState({ folderCollection });
      });
    }
    

    this.setState({
        openDialog: true,
        dialogTitle: name,
        folderId: id,
        folderName: name,
        dialogOption: 'folderSelect',
        fullScreenDialog: true,
        value: 0,
        
    });
  }

  onAddFolderClick = () => {
    this.setState({
        openDialog: true,
        dialogTitle: "Add Folder",
        dialogOption: 'addFolder',
        addFolderName: '',
        fullScreenDialog: false
    });
  }

  onEditClick = (id, name) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Edit folder",
        selectedId: id,
        dialogOption: 'edit',
        selectedFileName: name,
        selectedFolder: 'default',
        fullScreenDialog: false
    });
  }

  onCreateSubFolderClick = (id) => {
    this.setState({
      openDialog: true,
      dialogTitle: "Create Subfolder",
      selectedId: id,
      dialogOption: 'createSubFolder',
      fullScreenDialog: false,
      selectedSubFolderName: ''
    });
  }

  onDeleteClick = (id) => {
    this.setState({
        openDialog: true,
        dialogTitle: "Remove folder",
        selectedId: id,
        dialogOption: 'delete',
        fullScreenDialog: false
    });
  }

  onDeleteAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'deleteAll',
        dialogTitle: "Remove folder",
        fullScreenDialog: false
    });
  }

  onMoveAllClick = () => {
    this.setState({
        openDialog: true,
        dialogOption: 'moveAll',
        dialogTitle: "Move file",
        selectedFolder: 'default',
        fullScreenDialog: false
    });
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  _handleEditFileNameChange = e => {
    this.setState({
        selectedFileName: e.target.value
    });
  }

  _handleEditSubFolderNameChange = e => {
    this.setState({
        selectedSubFolderName: e.target.value
    });
  }

  handleSelectionChange = event => {
    this.setState({ selectedFolder: event.target.value });
  };

  handleEditFile = () => {

    if(directoryMode[0].mode){

      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/media_folder/' + this.state.selectedId).update({ name: this.state.selectedFileName });

    }
    else{
      app.database().ref().child('/media_folder/' + this.state.selectedId).update({ name: this.state.selectedFileName });
    }
    

    this.setState({
      openDialog: false,
      selectedFolder: 'default',
      selectedFileName: '',
      selected: []
  });
    
  }

  handleCreateSubFolder = () => {
    var postData = {
      name: this.state.selectedSubFolderName,
      created: firebase.database.ServerValue.TIMESTAMP,
      total: 0
    };

    if(directoryMode[0].mode){
      // Get a key for a new Post.
      var newPostKey = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/media_folder/' + this.state.selectedId +'/sub_folder').push().key;;

      // Write the new post's data simultaneously in the posts list and the user's post list.
      var updates = {};
      updates['/media_folder/' + this.state.selectedId +'/sub_folder/' + newPostKey] = postData;

      app.database().ref("https://suntec-f5bcf-44a5b.firebaseio.com/").update(updates);
    }
    else
    {
      // Get a key for a new Post.
      var newPostKey = app.database().ref().child('/media_folder/' + this.state.selectedId +'/sub_folder').push().key;;

      // Write the new post's data simultaneously in the posts list and the user's post list.
      var updates = {};
      updates['/media_folder/' + this.state.selectedId +'/sub_folder/' + newPostKey] = postData;

      app.database().ref().update(updates);
    }
    

    this.setState({
      openDialog: false,
      selectedSubFolderName: '',
      selected: []
  });
  }

  handleRemoveFile = () => {

    if(directoryMode[0].mode){

      let ref = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media');

      ref.once('value', snapshot => {

        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          
          if(item.folder === this.state.selectedId){
              
            app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media').child(childSnapshot.key).remove().then(() => {
        
                firebase.storage().ref().child(item.fullPath).delete().then(() => {
                    
                }) 
        
              })
          }
          
        });
      });

      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref(this.props.dataName).child(this.state.selectedId).remove()

    }
    else
    {
      let ref = app.database().ref('media');

      ref.once('value', snapshot => {

        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          
          if(item.folder === this.state.selectedId){
              
            app.database().ref('media').child(childSnapshot.key).remove().then(() => {
        
                firebase.storage().ref().child(item.fullPath).delete().then(() => {
                    
                }) 
        
              })
          }
          
        });
      });

      app.database().ref(this.props.dataName).child(this.state.selectedId).remove()

    }
    
    this.setState({
      openDialog: false,
      selectedId: '',
      selected: []
    });
    
  }

  handleRemoveAllFile = () => {

    this.state.selected.forEach(items => {

      const folder_content = []
      const campaign_content = []

      this.state.mediaCollection.map(val => { 
        if(val.folder === items){
          folder_content.push(val)
        }
      })

      folder_content.map(valFolderContent =>{
        this.state.campaignCollection.map(val => {
          val.content.map(contentContainerVal =>{
            contentContainerVal.map(contentVal=> {
              if(contentVal.content === valFolderContent.id){
                campaign_content.push({ content: contentVal.content, campaign: val.id, name: val.name, folder: valFolderContent.folder })
              }
            })
          })
        })
      })

      const currentIndex = campaign_content.map(val => { return val.folder }).indexOf(items)

      if(currentIndex === -1){

        if(directoryMode[0].mode){
          let ref = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media');

          ref.once('value', snapshot => {
  
            snapshot.forEach(childSnapshot => {
                const item = childSnapshot.val();
                
                if(item.folder === items){
                    
                  app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media').child(childSnapshot.key).remove().then(() => {
                
                        firebase.storage().ref().child(item.fullPath).delete().then(() => {
                            
                        }) 
                
                    })
  
                }
                
                });
            });
  
            app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref(this.props.dataName).child(items).remove()
        }
        else
        {
          let ref = app.database().ref('media');

          ref.once('value', snapshot => {
  
            snapshot.forEach(childSnapshot => {
                const item = childSnapshot.val();
                
                if(item.folder === items){
                    
                  app.database().ref('media').child(childSnapshot.key).remove().then(() => {
                
                        firebase.storage().ref().child(item.fullPath).delete().then(() => {
                            
                        }) 
                
                    })
  
                }
                
                });
            });
  
            app.database().ref(this.props.dataName).child(items).remove()
        }
        
      }

    })

    this.setState({
      openDialog: false,
      selected: []
    });
  }

  handleMoveAllFile = () => {
    
    if(this.state.selectedFolder !== 'default'){
      this.state.selected.forEach(items => {

        if(directoryMode[0].mode){

          app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/media/' + items).update({ folder: this.state.selectedFolder });
  
          app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('/media_folder/' + this.state.selectedFolder).child('total').transaction( total => {
            
            return total + 1;
          })

        }
        else
        {
          app.database().ref().child('/media/' + items).update({ folder: this.state.selectedFolder });
  
          app.database().ref().child('/media_folder/' + this.state.selectedFolder).child('total').transaction( total => {
            
            return total + 1;
          })
        }
        
      })
    }

    this.setState({
        openDialog: false,
        selectedFolder: 'default',
        selected: []
    });

  }

  _handleFolderNameChange = e => {
    this.setState({
        addFolderName: e.target.value
    });
  }

  handleAddFolder = async event => {

    var postData = {
        name: this.state.addFolderName,
        created: firebase.database.ServerValue.TIMESTAMP,
        total: 0
    };

    if(directoryMode[0].mode){
      // Get a key for a new Post.
      var newPostKey = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('media_folder').push().key;

      // Write the new post's data simultaneously in the posts list and the user's post list.
      var updates = {};
      updates['/media_folder/' + newPostKey] = postData;

      app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().update(updates);
    }
    else
    {
      // Get a key for a new Post.
      var newPostKey = app.database().ref().child('media_folder').push().key;

      // Write the new post's data simultaneously in the posts list and the user's post list.
      var updates = {};
      updates['/media_folder/' + newPostKey] = postData;

      app.database().ref().update(updates);
    }
    

    this.setState({
        openDialog: false,
        addFolderName: '',
        selected: []
    });
  }

  handleTabsChange = (event, value) => {
    this.searchReset();
    this.setState({ value });
  };

  handleChange (e) {

    var filterData = this.state.allMediaData.filter(item => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

    this.setState({ mediaData: filterData, filtertxt:e.target.value.toLowerCase()  });

  }

  searchReset(){
    var filterData = this.state.allMediaData.filter(item => item.name.toLowerCase().includes(""));

    this.setState({ mediaData: filterData, filtertxt:""  });
  }

  renderDialog = () => {

    if(this.state.dialogOption === 'folderSelect'){

      const subfolderCollection = []

      if(directoryMode[0].mode){
        let refSubFolder = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media_folder/'+this.state.folderId+"/sub_folder");

        refSubFolder.on('value', subfolderSnapshot => {
  
          subfolderSnapshot.forEach(subfolderChildSnapshot => {
            const item = subfolderChildSnapshot.val();
            item.key = subfolderChildSnapshot.key;
            
            subfolderCollection.push({id:item.key, created: item.created, total: item.total, name: item.name });
            
          });
  
        });
      }
      else
      {
        let refSubFolder = app.database().ref('media_folder/'+this.state.folderId+"/sub_folder");

        refSubFolder.on('value', subfolderSnapshot => {
  
          subfolderSnapshot.forEach(subfolderChildSnapshot => {
            const item = subfolderChildSnapshot.val();
            item.key = subfolderChildSnapshot.key;
            
            subfolderCollection.push({id:item.key, created: item.created, total: item.total, name: item.name });
            
          });
  
        });
      }
      

      return(<DialogContent style={{paddingLeft:0, paddingRight:0}}>
        <AppBar className={this.props.classes.AppBar} position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleTabsChange}
            indicatorColor="primary"
            textColor="primary"

          >
            <Tab className={this.props.classes.tab} disableRipple label="Media" />
            <Tab className={this.props.classes.tab} disableRipple label="SubFolder" />
          </Tabs>
        </AppBar>
        {this.state.value === 0 && 
        <TabContainer >
          <DialogContent style={{paddingLeft:0, paddingRight:0}}>
              <CustomizedFolderData 
                rows={this.props.isMobile ? [
                  { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
                  { id: 'imageUrl', numeric: false, disablePadding: false, label: 'File Thumbnail', sortable: true, thumbnail: true },
                ] :  [
                  { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
                  { id: 'imageUrl', numeric: false, disablePadding: false, label: 'File Thumbnail', sortable: true, thumbnail: true },
                  { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
                ]} 
                data={this.state.mediaData}
                orderBy={'created'}
                search={(e) => {this.handleChange(e)}}
                folderCollection={this.state.folderCollection}
                preview={true}
                movefile={true}
                dataName='media'
                selectedFolder={this.state.folderId}
                isMobile={this.props.isMobile}/>
          </DialogContent>
        </TabContainer> }
        {this.state.value === 1 && 
        <TabContainer>
            <CustomizedSubFolderTable 
              rows={this.props.isMobile ? [
                { id: 'name', numeric: false, disablePadding: true, label: 'Folder Name', sortable: true },
                { id: 'total', numeric: false, disablePadding: false, label: 'Total Files', sortable: true }
              ] : [
                { id: 'name', numeric: false, disablePadding: true, label: 'Folder Name', sortable: true },
                { id: 'total', numeric: false, disablePadding: false, label: 'Total Files', sortable: true },
                { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
              ]} 
              data={subfolderCollection}
              orderBy={'created'}
              folderCollection={subfolderCollection}
              movefile={false}
              folderId={this.state.folderId}
              dataName={'media_folder/'+ this.state.folderId+"/sub_folder"}
              isMobile={this.props.isMobile} />
        </TabContainer> }
      </DialogContent>)
    } 

    else if(this.state.dialogOption === 'createSubFolder'){
      return(
          <div>
          <DialogContent>
              <TextField
                  margin="dense"
                  id="name"
                  label="Folder Name"
                  fullWidth
                  value={this.state.selectedSubFolderName} 
                  onChange={this._handleEditSubFolderNameChange}
              />
          </DialogContent>
          <DialogActions>
              <Button onClick={this.handleCreateSubFolder} color="primary">
              Submit
              </Button>
              <Button onClick={this.handleClose} color="default">
              Cancel
              </Button>
          </DialogActions>
          </div>
      )
    }

    else if(this.state.dialogOption === 'edit'){
        return(
            <div>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="name"
                    label="File Name"
                    fullWidth
                    value={this.state.selectedFileName} 
                    onChange={this._handleEditFileNameChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleEditFile} color="primary">
                Submit
                </Button>
                <Button onClick={this.handleClose} color="default">
                Cancel
                </Button>
            </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'delete'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these folder?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'deleteAll'){
        return(
            <div>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to permanently delete these folder?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemoveAllFile} color="default">
                    Yes
                    </Button>
                    <Button color="primary" onClick={this.handleClose}>
                    No
                    </Button>
                </DialogActions>
            </div>
        )
    }

    else if(this.state.dialogOption === 'moveAll'){
        return(
            <div>
            <DialogContent style={{ width: 200}}>
                <FormControl style={{ marginTop: 20 }} fullWidth variant="outlined" noValidate autoComplete="off">
                    <InputLabel
                    ref={ref => {
                        this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-age-simple"
                    >
                    Folder
                    </InputLabel>
                    <Select
                    value={this.state.selectedFolder}
                    onChange={this.handleSelectionChange}
                    input={
                        <OutlinedInput
                        labelWidth={100}
                        name="folder"
                        id="outlined-age-simple"
                        />
                    }
                    >
                    
                    <MenuItem value="default">
                        <em>Default</em>
                    </MenuItem>
                    {this.props.folderCollection.map(n => {
                        return(
                        <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                        )
                    })}

                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleMoveAllFile} color="primary">
                Yes
                </Button>
                <Button onClick={this.handleClose} color="primary" autoFocus>
                No
                </Button>
            </DialogActions>
            </div>
        )
    }
      
    else if(this.state.dialogOption === 'addFolder'){
      return(
        <div>
          <DialogContent>
            <FormControl fullWidth variant="outlined" noValidate autoComplete="off" onSubmit={this.handleAddFolder}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Folder Name"
                fullWidth
                value={this.state.addFolderName} 
                onChange={this._handleFolderNameChange}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAddFolder} color="primary">
              Add
            </Button>
            <Button onClick={this.handleClose} color="default">
              Cancel
            </Button>
          </DialogActions>
        </div>
      )
    } 
  }

  handleToggle = (event, id) => { 

    if(id === this.state.selectedTableCell){
      this.setState({ selectedTableCell: ''})
    } else {
      this.setState({ selectedTableCell: id})
    }
    
  }

  componentWillMount(){

    if(directoryMode[0].mode){
      let ref = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media');

      ref.on('value', snapshot => {
        const mediaCollection = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          mediaCollection.push({ id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder, user: item.user, noted: item.noted });
  
        });
  
        let refCampaign = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign');

        let refAdsCampaign = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign_ads');
  
        refCampaign.on('value', campaignSnapshot => {
          const campaignCollection = [];
  
          campaignSnapshot.forEach(CampaignChildSnapshot => {
            const item = CampaignChildSnapshot.val();
            item.key = CampaignChildSnapshot.key;
            
            campaignCollection.push({id:item.key, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, name: item.name, content: item.content });
            
          });

          refAdsCampaign.once('value', campaignSnapshot1 => {
            const campaignAdsCollection = [];
      
            campaignSnapshot1.forEach(CampaignChildSnapshot1 => {
              const item1 = CampaignChildSnapshot1.val();
              item1.key = CampaignChildSnapshot1.key;
              
              campaignAdsCollection.push({id:item1.key, created: item1.created, 
                updated: item1.updated, template: item1.template, template_name: item1.template_name, 
                name: item1.name, content: item1.content });
              
            });
      
            this.setState({ campaignCollection, mediaCollection, campaignAdsCollection });
          });

        });
        
      });

    }
    else{
      let ref = app.database().ref('media');

      ref.on('value', snapshot => {
        const mediaCollection = [];
  
        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;
          
          mediaCollection.push({ id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder, user: item.user, noted: item.noted });
  
        });
  
        let refCampaign = app.database().ref('campaign');

        let refAdsCampaign = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign_ads');
  
        refCampaign.on('value', campaignSnapshot => {
          const campaignCollection = [];
  
          campaignSnapshot.forEach(CampaignChildSnapshot => {
            const item = CampaignChildSnapshot.val();
            item.key = CampaignChildSnapshot.key;
            
            campaignCollection.push({id:item.key, created: item.created, updated: item.updated, template: item.template, template_name: item.template_name, name: item.name, content: item.content });
            
          });

          refAdsCampaign.once('value', campaignSnapshot1 => {
            const campaignAdsCollection = [];
      
            campaignSnapshot1.forEach(CampaignChildSnapshot1 => {
              const item1 = CampaignChildSnapshot1.val();
              item1.key = CampaignChildSnapshot1.key;
              
              campaignAdsCollection.push({id:item1.key, 
                created: item1.created, 
                updated: item1.updated, 
                template: item1.template, 
                template_name: item1.template_name, 
                name: item1.name, content: item1.content });
              
            });
      
            this.setState({ campaignCollection, campaignAdsCollection, mediaCollection });
          });

        });
        
      });
    }
    

    
  }

  
  
  render() {
    const { classes, rows, data } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.openDialog}
            maxWidth='xl'
            fullScreen={this.state.fullScreenDialog}
          >
          {this.state.fullScreenDialog ?<div></div> : <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.state.dialogTitle}
          </DialogTitle>}

          {this.state.fullScreenDialog ? 
            <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography className={classes.title} color="inherit" variant="h6" >
                {this.state.dialogTitle}
              </Typography>
            </Toolbar>
          </AppBar> : <div></div>}

          {this.renderDialog()}
        </Dialog>
        <EnhancedTableToolbar numSelected={selected.length} onDeleteAllClick={this.onDeleteAllClick} onMoveAllClick={this.onMoveAllClick} movefile={this.props.movefile} onAddFolderClick={this.onAddFolderClick}/>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
            />
            <TableBody>
              
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);

                  const folder_content = []
                  const campaign_content = []

                  this.state.mediaCollection.map(val => { 
                    if(val.folder === n.id){
                      folder_content.push(val)
                    }
                  })

                  folder_content.map(valFolderContent =>{
                    this.state.campaignCollection.map(val => {
                      val.content.map(contentContainerVal =>{
                        contentContainerVal.map(contentVal=> {
                          if(contentVal.content === valFolderContent.id){
                            campaign_content.push({ content: contentVal.content, campaign: val.id, name: val.name, folder: valFolderContent.folder })
                          }
                        })
                      })
                    })
                  })

                  folder_content.map(valFolderContent =>{
                    this.state.campaignAdsCollection.map(val => {
                      val.content.map(contentContainerVal =>{
                        contentContainerVal.map(contentVal=> {
                          if(contentVal.content === valFolderContent.id){
                            campaign_content.push({ content: contentVal.content, campaign: val.id, name: val.name, folder: valFolderContent.folder })
                          }
                        })
                      })
                    })
                  })

                  const currentIndex = campaign_content.map(val => { return val.folder }).indexOf(n.id)

                  return (
                    [<TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell onClick={event => this.handleClick(event, n.id)} padding="checkbox">
                        <Checkbox checked={isSelected} />
                      </TableCell>

                      <TableCell onClick={event => this.handleToggle(event, n.id)} align="left" padding="none">{n.name}</TableCell>

                      <TableCell onClick={event => this.handleToggle(event, n.id)} align="left" padding="default">{folder_content.length}</TableCell>

                      {!this.props.isMobile ? <TableCell align="left" padding="none" >
                      <div className={classes.actionButtons}>
                      <Tooltip title="Enter Folder">
                            <IconButton color={"primary"} onClick={() => this.onFolderClick(n.id, n.name) }>
                                <FolderIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Add Subfolder">
                            <IconButton onClick={() => this.onCreateSubFolderClick(n.id, n.name) }>
                                <CreateNewFolder fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                            <IconButton onClick={() => this.onEditClick(n.id, n.name) }>
                                <EditIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete">
                            <IconButton disabled={currentIndex !== -1 ? true : false}  onClick={() => this.onDeleteClick(n.id) }>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                            </Tooltip>
                        </div>
                      </TableCell> : ''}
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Id</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.id}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Created by</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(n.created)}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell style={{ fontWeight: '600'}} padding={'default'} colSpan={1}>Subfolder</TableCell>
                      <TableCell padding={'none'} colSpan={11}>{n.sub_folder ? Object.keys(n.sub_folder).length : 0}</TableCell>
                    </TableRow>,
                    <TableRow style={{ display: this.state.selectedTableCell === n.id && this.props.isMobile  ? '': 'none', backgroundColor: '#f1f1f1'}}>
                      <TableCell padding={'default'} colSpan={12}>
                        <div className={classes.actionButtons}>
                            <IconButton color={"primary"} onClick={() => this.onFolderClick(n.id, n.name) }>
                                <FolderIcon fontSize="small" />
                            </IconButton>
                            <Tooltip title="Add Subfolder">
                            <IconButton onClick={() => this.onCreateSubFolderClick(n.id, n.name) }>
                                <CreateNewFolder fontSize="small" />
                            </IconButton>
                            </Tooltip>
                            <IconButton onClick={() => this.onEditClick(n.id, n.name) }>
                                <EditIcon fontSize="small" />
                            </IconButton>
                            { firebase.auth().currentUser.email == "support@pgkmediagroup.com" ? <IconButton disabled={currentIndex !== -1 ? true : false}  onClick={() => this.onDeleteClick(n.id) }>
                                <DeleteIcon fontSize="small" />
                            </IconButton> : null }
                        </div>
                      </TableCell>
                    </TableRow>]
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[20, 50, 200]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);