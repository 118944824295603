import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import {sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CustomizedTable from './CustomizedTable'
import Paper from '@material-ui/core/Paper';
import AutoFitImage from 'react-image-autofit-frame';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import { compose } from "recompose";
import ReactTimeout from 'react-timeout'
import { MovieRounded } from '@material-ui/icons';
import ReactPlayer from 'react-player'
import DayPicker from './DayPicker'
import { Calendar } from 'react-date-range';
import moment from "moment";
import TimeKeeper from 'react-timekeeper';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import _ from 'lodash';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import InputColor from 'react-input-color';
import InputBase from '@material-ui/core/InputBase';
import ResizableRect from 'react-resizable-rotatable-draggable'
import { directoryMode } from '../firebase/config';
import app from "../firebase/base";
import Input from '@material-ui/core/Input';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PanZoom } from 'react-easy-panzoom'

const styles = theme => ({
    root: {
        width: '100%',
    },
    container: {
      border: '5px solid #f50057'
    },
    mediaContainer: {
      position: 'absolute',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: 10,
      flex: 1,
    },
});

const windowHeight = window.innerHeight - 120

const SortableItem = sortableElement(({handleItemChange, deleteItem, value, index, allindex,  handleToggle, checked, mediaCollection, allContainer, currentContainer, currentSelectedContent}) => 
{

  
  var currentIndex = mediaCollection.map(val => {
    return val.id
  }).indexOf(value.content);

  if(value.schedule){
    if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
      value.active = false
    } else {
      value.active = true
    }
  } else {
    value.active = true
  }

  if(value.selected == undefined || value.selected == false){
    value.selected = false;
  }
  else
  {
    value.selected = true;
  }
 
  
  if(mediaCollection[currentIndex].type == "video/mp4"){

    return(
      <ListItem style={{ backgroundColor: value.active ? null : "#ff8d8d", width: '100%' }} role={undefined}  disableRipple key={index} selected={allindex == currentSelectedContent ? true : false } divider dense button>
         <Typography style={{ fontSize: 20, color: value.schedule ? "#0030ff" : "#efefef" ,paddingTop: 5 }} variant="caption" display="block" gutterBottom>
         •
        </Typography>
        <Checkbox
          key={allindex}
                  checked={value.selected}
                  value="primary"
                  onChange={handleItemChange(value)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
        <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
          {allindex+1}
        </Typography>
        <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
        <Typography style={{ width: 200, paddingLeft: 10 }} noWrap  display="inline">
        {mediaCollection[currentIndex].name}
          </Typography>
      </ListItem>
    )
  } else {
    
    return(
      <ListItem style={{ backgroundColor: value.active ? null : "#ff8d8d", width: '100%' }} role={undefined}  disableRipple key={index} selected={allindex == currentSelectedContent ? true : false } divider dense button>
        <Typography style={{ fontSize: 20, color: value.schedule ? "#0030ff" : "#efefef" ,paddingTop: 5 }} variant="caption" display="block" gutterBottom>
         •
        </Typography>
        <Checkbox
                  key={allindex}
                  checked={value.selected}
                  value="primary"
                  onChange={handleItemChange(value)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
        <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
          {allindex+1}
        </Typography>
        <Avatar src={mediaCollection[currentIndex].imageUrl} >
        </Avatar>
        <Typography style={{ width: 200, paddingLeft: 10 }} noWrap  display="inline">
        {mediaCollection[currentIndex].name}
      </Typography>
      </ListItem>
    )
  }

})

const SortableTextItem = sortableElement(({ value, currentIndex, selectedTextId }) => 
{
  var paddingLeft = value.paddingLeft + 'px';
  var paddingRight = value.paddingRight + 'px';
  var paddingTop = value.paddingTop + 'px';
  var paddingBottom = value.paddingBottom + 'px';
  var width = value.width/4
  var marginLeft = value.x + 'px';
  var marginTop = value.y + 'px';

  return(
    <ListItem style={{ width: '100%' }} role={undefined}  
    disableRipple key={currentIndex} selected={selectedTextId == currentIndex ? true : false}  divider dense button>

      <Typography style={{ height: 25 }}noWrap  display="inline">
      {value.text}
      </Typography>
    </ListItem>
  )
})

const SortableContainer = sortableContainer(({children}) => {
  return <List>{children}</List>;
});

var timeout = [];

class CustomizedAddCampaign extends Component {

  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.textInput = React.createRef();
  }

    state = {
        selectedTemplate: 'default',
        selectedCampaignAds: 'none',
        templateCollection: [],
        stationCollection: [],
        stationCollectionNoChange: [],
        campaignAdsCollection: [],
        contents: [[]],
        allContainer: [''],
        openDialog: false,
        openSecondDialog: false,
        dialogSecondOption: '',
        dialogSecondTitle: '',
        dialogOption: '',
        dialogTitle: '',
        mediaData:[],
        folderCollection: [],
        currentContainer: 0,
        checked: [],
        campaignName: '',
        mediaCollection: [],
        contentDuration: 0,
        transitionSelected: 'fade',
        dialogTitleVisible: true,
        currentSlideIndex: [0],
        created: null,
        currentCampaignId: null,
        selectedStartDate:  moment(new Date()),
        selectedEndDate: moment(new Date()),
        selectedStartTime: '12:00 am',
        selectedEndTime: '11:59 pm',
        selectedSecondStartDate:  moment(new Date()),
        selectedSecondEndDate: moment(new Date()),
        selectedSecondStartTime: '12:00 am',
        selectedSecondEndTime: '11:59 pm',
        switchChecked: false,
        switchSecondChecked: false,
        stretchValue: true,
        stretchSelected: false,
        fullScreenDialog: false,
        currentSelectedContent: 0,
        isMobile: false,
        total_mix_content_duration: 0,
        selectedCampaignInterval: 3,
        percentage_tc: 0,
        percentage_ytn: 0,
        newPostKey: null,
        newText: [],
        selectedTextId: 0,
        selectedFont: 'Arial',
        addToText: false,
        addToBackground: false,
        textWidth: 100,
        textHeight: 100,
        textTop: 0,
        textLeft: 0,
        rotateAngle: 0,
        bgTransparent: true,
        backgroundImage: null,
        backgroundImageName: 'Background Image',
        backgroundImageActive: false,
        backgroundImageColor: '#ffffff',
        textUid: 0,
        selectAllStation: false,
        gridTemplate: [
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
          ["","","",""],
        ],
        gridTemplateActive: false,
        gridText: '',
        gridTemplateFullscreen: false,
        transformClick: false,
         currentSelectedGrid: "0_0"
    }

    componentDidMount(){
      
    if(directoryMode[0].mode){
      let folderRef = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media_folder');

      folderRef.once('value', foldersnapshot => {
        const folderCollection = [];

        foldersnapshot.forEach(childFolderSnapshot => {
            const folderItem = childFolderSnapshot.val();
            folderItem.key = childFolderSnapshot.key;
            
            folderCollection.push({ id:folderItem.key, name: folderItem.name, created: folderItem.created, total: folderItem.total });
            
        });

        let ref = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('media');

        ref.once('value', snapshot => {
            const mediaCollection = [];

            snapshot.forEach(childSnapshot => {
              const item = childSnapshot.val();
              item.key = childSnapshot.key;
              
              mediaCollection.push({id:item.key, duration: item.duration, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder });
              
            });

          

            let templateRef = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('template');

            templateRef.once('value', templateSnapshot => {
                const templateCollection = [];

                templateSnapshot.forEach(templateChildSnapshot => {
                    const templateItem = templateChildSnapshot.val();
                    templateItem.key = templateChildSnapshot.key;
                    templateCollection.push({ id:templateItem.key, name: templateItem.name, created: templateItem.created, container: templateItem.container });
                });

                let campaignAdsRef = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign_ads');

                campaignAdsRef.once('value', campaignAdsSnapshot => {
                      var campaignAdsCollection = [];
          
                      
                      campaignAdsSnapshot.forEach(campaignAdsChildSnapshot => {
                          const campaignAdsItem = campaignAdsChildSnapshot.val();
                          campaignAdsItem.key = campaignAdsChildSnapshot.key;
                          
                          campaignAdsCollection.push({ id:campaignAdsItem.key, name: campaignAdsItem.name, created: campaignAdsItem.created, updated: campaignAdsItem.updated, template: campaignAdsItem.template, template_name: campaignAdsItem.template_name, content: campaignAdsItem.content });
                          
                          
                          
                      });

                      let stationRef = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('station');

                      stationRef.once('value', stationSnapshot => {
                            var stationCollection = [];
                            var stationCollection2 = [];
                            
                            stationSnapshot.forEach(stationChildSnapshot => {
                                const stationItem = stationChildSnapshot.val();
                                stationItem.key = stationChildSnapshot.key;
                                
                                stationCollection.push({ 
                                  id:stationItem.key, 
                                  name: stationItem.name, 
                                  created: stationItem.created, 
                                  updated: stationItem.updated, 
                                  campaign: stationItem.campaign ? stationItem.campaign : null,
                                  connected: stationItem.connected,
                                  zone: stationItem.zone,
                                  used: stationItem.used,
                                  app_version: stationItem.app_version ? stationItem.app_version : "1.0.0",
                                  is_playing: stationItem.is_playing ? true : false,
                                  push_date: stationItem.push_date ? stationItem.push_date : "0000000000",
                                  sim_serial: stationItem.sim_serial ? stationItem.sim_serial : "0",
                                  total_file_in_app: stationItem.total_file_in_app ? stationItem.total_file_in_app : "null"
                                });

                                stationCollection2.push({ 
                                  id:stationItem.key, 
                                  name: stationItem.name, 
                                  created: stationItem.created, 
                                  updated: stationItem.updated, 
                                  campaign: stationItem.campaign ? stationItem.campaign : null,
                                  connected: stationItem.connected,
                                  zone: stationItem.zone,
                                  used: stationItem.used,
                                  app_version: stationItem.app_version ? stationItem.app_version : "1.0.0",
                                  is_playing: stationItem.is_playing ? true : false,
                                  push_date: stationItem.push_date ? stationItem.push_date : "0000000000",
                                  sim_serial: stationItem.sim_serial ? stationItem.sim_serial : "0",
                                  total_file_in_app: stationItem.total_file_in_app ? stationItem.total_file_in_app : "null"
                                });

                            });

                            this.storeCollection(folderCollection, mediaCollection, templateCollection, campaignAdsCollection, stationCollection, stationCollection2)
                      });
                });
          

                
            });
        });
      })
    }
    else
    {
      let folderRef = app.database().ref('media_folder');

      folderRef.once('value', foldersnapshot => {
        const folderCollection = [];

        foldersnapshot.forEach(childFolderSnapshot => {
            const folderItem = childFolderSnapshot.val();
            folderItem.key = childFolderSnapshot.key;
            
            folderCollection.push({ id:folderItem.key, name: folderItem.name, created: folderItem.created, total: folderItem.total });
            
        });

        let ref = app.database().ref('media');

        ref.once('value', snapshot => {
            const mediaCollection = [];

            snapshot.forEach(childSnapshot => {
              const item = childSnapshot.val();
              item.key = childSnapshot.key;
              
              mediaCollection.push({id:item.key, duration: item.duration, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder });
              
            });

          

            let templateRef = app.database().ref('template');

            templateRef.once('value', templateSnapshot => {
                const templateCollection = [];

                templateSnapshot.forEach(templateChildSnapshot => {
                    const templateItem = templateChildSnapshot.val();
                    templateItem.key = templateChildSnapshot.key;
                    templateCollection.push({ id:templateItem.key, name: templateItem.name, created: templateItem.created, container: templateItem.container });
                });

                let campaignAdsRef = app.database().ref('campaign_ads');

                campaignAdsRef.once('value', campaignAdsSnapshot => {
                      var campaignAdsCollection = [];
          
                      
                      campaignAdsSnapshot.forEach(campaignAdsChildSnapshot => {
                          const campaignAdsItem = campaignAdsChildSnapshot.val();
                          campaignAdsItem.key = campaignAdsChildSnapshot.key;
                          
                          campaignAdsCollection.push({ id:campaignAdsItem.key, name: campaignAdsItem.name, created: campaignAdsItem.created, updated: campaignAdsItem.updated, template: campaignAdsItem.template, template_name: campaignAdsItem.template_name, content: campaignAdsItem.content });
                          
                          
                          
                      });

                      let stationRef = app.database().ref('station');

                      stationRef.once('value', stationSnapshot => {
                            var stationCollection = [];
                            var stationCollection2 = [];
                            
                            stationSnapshot.forEach(stationChildSnapshot => {
                                const stationItem = stationChildSnapshot.val();
                                stationItem.key = stationChildSnapshot.key;
                                
                                stationCollection.push({ 
                                  id:stationItem.key, 
                                  name: stationItem.name, 
                                  created: stationItem.created, 
                                  updated: stationItem.updated, 
                                  campaign: stationItem.campaign ? stationItem.campaign : null,
                                  connected: stationItem.connected,
                                  zone: stationItem.zone,
                                  used: stationItem.used,
                                  app_version: stationItem.app_version ? stationItem.app_version : "1.0.0",
                                  is_playing: stationItem.is_playing ? true : false,
                                  push_date: stationItem.push_date ? stationItem.push_date : "0000000000",
                                  sim_serial: stationItem.sim_serial ? stationItem.sim_serial : "0",
                                  total_file_in_app: stationItem.total_file_in_app ? stationItem.total_file_in_app : "null"
                                });

                                stationCollection2.push({ 
                                  id:stationItem.key, 
                                  name: stationItem.name, 
                                  created: stationItem.created, 
                                  updated: stationItem.updated, 
                                  campaign: stationItem.campaign ? stationItem.campaign : null,
                                  connected: stationItem.connected,
                                  zone: stationItem.zone,
                                  used: stationItem.used,
                                  app_version: stationItem.app_version ? stationItem.app_version : "1.0.0",
                                  is_playing: stationItem.is_playing ? true : false,
                                  push_date: stationItem.push_date ? stationItem.push_date : "0000000000",
                                  sim_serial: stationItem.sim_serial ? stationItem.sim_serial : "0",
                                  total_file_in_app: stationItem.total_file_in_app ? stationItem.total_file_in_app : "null"
                                });

                            });

                            this.storeCollection(folderCollection, mediaCollection, templateCollection, campaignAdsCollection, stationCollection, stationCollection2)
                      });
                });
          

                
            });
        });
      })
    }
      

      const windowSize = window.innerWidth;
        if(windowSize < 800){
          this.setState({ isMobile: true })
        } else {
          this.setState({ isMobile: false })
        }

      
      window.addEventListener("resize", this.handleResize );
    }

    handleResize  = () => {
      const windowSize = window.innerWidth;
      if(windowSize < 800){
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    }

    storeCollection = (folderCollection,  mediaCollection, templateCollection, campaignAdsCollection, stationCollection, stationCollection2) =>{

      
      //edit campaign
      if(this.props.editCampaign){

        let ref = this.props.ads ? 
        directoryMode[0].mode ? app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign_ads/'+ this.props.editCampaignId):  app.database().ref('campaign_ads/'+ this.props.editCampaignId) 
        : directoryMode[0].mode ? app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('campaign_tc/'+ this.props.editCampaignId) : app.database().ref('campaign_tc/'+ this.props.editCampaignId);

        ref.once('value', snapshot => {
          const item = snapshot.val();
          item.key = snapshot.key;
          const campaignName = item.name

          const contents = [[]]
          const allContainer = []
          const currentSlideIndex = []
          var total_mix_content_duration = 0;


          var duplicate_count = 0;
          var ads_count = 0;
          var tc_count = 0;

          if(item.template !== 'default')
          {
            const currentIndex = templateCollection.map(value => { return value.id}).indexOf(item.template)
            templateCollection[currentIndex].container.map((value, index) => { 

              if(item.content && item.content[index])
              {
                allContainer.push(item.content[index][0].content)
                contents.push(item.content[index])
              } else {
                allContainer.push("")
                contents.push([])
              }

              currentSlideIndex.push(0)
            })
            
            this.setState({ selectedCampaignInterval: item.ads_interval ? item.ads_interval : 3, selectedCampaignAds : item.ads_content ? item.ads_content : 'none' , switchChecked: item.schedule ? true : false, 
              selectedStartDate: item.schedule ? moment.unix(item.schedule.start_date) : moment(new Date()),
              selectedEndDate: item.schedule ? moment.unix(item.schedule.end_date) : moment(new Date()),
              selectedStartTime: item.schedule ? item.schedule.start_time : '12:00 am',
              selectedEndTime: item.schedule ? item.schedule.end_time : '11:59 pm',
              selectedSecondStartDate: item.content && item.content[0] && item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.start_date) : moment(new Date()),
              selectedSecondEndDate: item.content && item.content[0] && item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.end_date) : moment(new Date()),
              selectedSecondStartTime: item.content && item.content[0] && item.content[0][0].schedule ? item.content[0][0].schedule.start_time :'12:00 am',
              selectedSecondEndTime: item.content && item.content[0] && item.content[0][0].schedule ? item.content[0][0].schedule.end_time :'12:00 am',
              switchSecondChecked: item.content && item.content[0] && item.content[0][0].schedule ? true : false,
              currentCampaignId: item.key, 
              created: item.created, campaignName, 
              contentDuration: item.content && item.content[0] && item.content[0][0].duration? item.content[0][0].duration : null, 
              transitionSelected: item.content && item.content[0] ? item.content[0][0].transition : 'fade', 
              folderCollection, mediaCollection, templateCollection, selectedTemplate: item.template, allContainer, contents: contents, currentSlideIndex, 
              campaignAdsCollection,
              newText: item.text ? item.text : [],
              backgroundImage: item.background_image,
              backgroundImageName: item.background_image_name,
              backgroundImageActive: item.background_active,
              backgroundImageColor: item.background_image_color,
            });

            
          } else {

            if(item.ads_content != 'none' && item.ads_content){
              
              const currentIndex = campaignAdsCollection.map(value => { return value.id }).indexOf(item.ads_content);
              
              var duplicate_count = 0;
              var ads_count = 0;
              var tc_count = 0;
              var total_second_ads = 0
              var total_second_tc = 0
              var percentage_tc = 0;
              var percentage_ytn = 0;

              const contents = [[]];
              const ads_active = [];
              

              if(campaignAdsCollection[currentIndex].content[0].length * (item.ads_interval ? item.ads_interval - 1 : 2) > item.content[0].length){
        
                
                var total_content_number = campaignAdsCollection[currentIndex].content[0].length * (item.ads_interval ? item.ads_interval : 3);
                
                for (var i = 0; i < total_content_number; i++) {
                  
                  if(i == duplicate_count){
        
                    ads_active.push({ active: true});
                    contents[0].push(campaignAdsCollection[currentIndex].content[0][ads_count]);
                    
                    if(ads_count > campaignAdsCollection[currentIndex].content[0].length - 2){
                      ads_count = 0;
                    } else {
                      ads_count++;
                    }
        
                    duplicate_count = i + (item.ads_interval ? item.ads_interval : 3);


                  } else {
                    
                    ads_active.push({ active: false});
                    contents[0].push(item.content[0][tc_count]);
      
                    if(tc_count > item.content[0].length - 2){
                      tc_count = 0;
                    } else {
                      tc_count++;
                    }
                  }
        
                } 
         
              } else {
                var total_content_number = item.content[0].length / (item.ads_interval ? item.ads_interval - 1 : 2) + item.content[0].length;
        
                for (var i = 0; i < Math.round(total_content_number); i++) {
                  
                  if(i == duplicate_count){
        
                    ads_active.push({ active: true});
                    contents[0].push(campaignAdsCollection[currentIndex].content[0][ads_count]);
        
                    if(ads_count > campaignAdsCollection[currentIndex].content[0].length - 2){
                      ads_count = 0;
                    } else {
                      ads_count++;
                    }
        
                    duplicate_count = i + (item.ads_interval ? item.ads_interval : 3);
                  } else {
                    
                    ads_active.push({ active: false});
                    contents[0].push(item.content[0][tc_count]);
        
                    if(tc_count > item.content[0].length - 2){
                      tc_count = 0;
                    } else {
                      tc_count++;
                    }
                  }
                }
              }

              for (var i = 0; i < contents[0].length; i++) {
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_mix_content_duration += mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_mix_content_duration += parseInt(contents[0][i].duration)
                }

                if(ads_active[i].active){
                  if(contents[0][i].type == "video/mp4"){
                    const currentIndex2 = mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
                    total_second_ads += mediaCollection[currentIndex2].duration;
                  } 
                  else 
                  {
                    total_second_ads += parseInt(contents[0][i].duration)
                  }
                }
                else
                {
                  if(contents[0][i].type == "video/mp4"){
                    const currentIndex2 = mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
                    total_second_tc += mediaCollection[currentIndex2].duration;
                  } 
                  else 
                  {
                    total_second_tc += parseInt(contents[0][i].duration)
                  }
                }
              }

            }
           
            percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
            percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;

            currentSlideIndex.push(0)

            if(item.content && item.content[0])
            {
              allContainer.push(item.content[0][0].content)
            } else {
              allContainer.push('')
            }

            if(item.content && item.content[0] && item.content[0].length > 0){
              item.content[0].map(value => { 
                if(value.schedule){
                  if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
                    value.active = false
                  } else {
                    value.active = true
                  }
                } else {
                  value.active = true
                }
              })
            }
            

            this.setState({ percentage_ytn, percentage_tc, selectedCampaignInterval: item.ads_interval ? item.ads_interval : 3, total_mix_content_duration, 
              contents : item.content ? item.content : [[]], selectedCampaignAds : item.ads_content ? item.ads_content : 'none', switchChecked: item.schedule ? true : false, 
              selectedStartDate: item.schedule ? moment.unix(item.schedule.start_date) : moment(new Date()),
              selectedEndDate: item.schedule ? moment.unix(item.schedule.end_date) : moment(new Date()),
              selectedStartTime: item.schedule ? item.schedule.start_time : '12:00 am',
              selectedEndTime: item.schedule ? item.schedule.end_time : '11:59 pm',
              selectedSecondStartDate: item.content && item.content[0] && item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.start_date) : moment(new Date()),
              selectedSecondEndDate: item.content && item.content[0] && item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.end_date) : moment(new Date()),
              selectedSecondStartTime: item.content && item.content[0] && item.content[0][0].schedule ? item.content[0][0].schedule.start_time :'12:00 am',
              selectedSecondEndTime: item.content && item.content[0] && item.content[0][0].schedule ? item.content[0][0].schedule.end_time :'11:59 pm',
              switchSecondChecked: item.content && item.content[0] && item.content[0][0].schedule ? true : false,
              currentCampaignId: item.key, 
              created: item.created, campaignName, 
              contentDuration: item.content && item.content[0] && item.content[0][0].duration? item.content[0][0].duration : null, 
              transitionSelected: item.content?item.content[0][0].transition : 'fade',  
              folderCollection, mediaCollection, templateCollection, stationCollection, stationCollectionNoChange: stationCollection2, selectedTemplate: item.template, allContainer, 
              currentSlideIndex, campaignAdsCollection,
              newText: item.text ? item.text : [],
              backgroundImage: item.background_image ? item.background_image : null,
              backgroundImageName: item.background_image_name ? item.background_image_name : 'Background Image',
              backgroundImageActive: item.background_active ? true : false,
              backgroundImageColor: item.background_image_color ?item.background_image_color: '#ffffff',
              });
          }

        });
      } else {

        if(directoryMode[0].mode){
          var newPostKey = app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().child('campaign_tc').push().key;
          this.setState({ newPostKey, folderCollection, mediaCollection, templateCollection, campaignAdsCollection, stationCollectionNoChange: stationCollection2, stationCollection });
        }
        else
        {
          var newPostKey = app.database().ref().child('campaign_tc').push().key;
          this.setState({ newPostKey, folderCollection, mediaCollection, templateCollection, campaignAdsCollection, stationCollectionNoChange: stationCollection2, stationCollection });
        }
        
      }
    }

    handleCampaignIntervalChange = event => {

      if(this.state.contents[0].length > 0){
        const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);
        var duplicate_count = 0;
        var ads_count = 0;
        var tc_count = 0;
        var total_mix_content_duration = 0;
  
        const contents = [[]];
        const ads_active = [];
  
        if(this.state.campaignAdsCollection[currentIndex].content[0].length * (event.target.value-1) > this.state.contents[0].length){
  
          var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * event.target.value;
  
          for (var i = 0; i < total_content_number; i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + event.target.value;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(this.state.contents[0][tc_count]);
  
  
              if(tc_count > this.state.contents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
  
          } 
  
        } else {
          var total_content_number = this.state.contents[0].length / (event.target.value-1) + this.state.contents[0].length;
  
          for (var i = 0; i < Math.round(total_content_number); i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + event.target.value;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(this.state.contents[0][tc_count]);
  
              if(tc_count > this.state.contents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
          }
        }

        var total_second_ads = 0
        var total_second_tc = 0
        var percentage_tc = 0;
        var percentage_ytn = 0;
  
        for (var i = 0; i < contents[0].length; i++) {
          if(contents[0][i].type == "video/mp4"){
            const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
            total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
          } 
          else 
          {
            total_mix_content_duration += parseInt(contents[0][i].duration)
          }

          if(ads_active[i].active){
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_ads += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_ads += parseInt(contents[0][i].duration)
            }
          }
          else
          {
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_tc += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_tc += parseInt(contents[0][i].duration)
            }
          
          }

        }

        percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
        percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;

        this.setState({ percentage_tc, percentage_ytn, selectedCampaignInterval: event.target.value, total_mix_content_duration })
        
      }
      
      
    }


    handleCampaignAdsChange = event => {

      if(event.target.value != 'none' && this.state.contents[0].length > 0){
        const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id }).indexOf(event.target.value);
        var duplicate_count = 0;
        var ads_count = 0;
        var tc_count = 0;
        var total_mix_content_duration = 0;
  
        const contents = [[]];
        const ads_active = [];
  
        if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > this.state.contents[0].length){
  
          var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
  
          for (var i = 0; i < total_content_number; i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(this.state.contents[0][tc_count]);
  
  
              if(tc_count > this.state.contents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
  
          } 
  
        } else {
          var total_content_number = this.state.contents[0].length / (this.state.selectedCampaignInterval-1) + this.state.contents[0].length;
  
          for (var i = 0; i < Math.round(total_content_number); i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
  
              if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
              
              ads_active.push({ active: false});
              contents[0].push(this.state.contents[0][tc_count]);
  
              if(tc_count > this.state.contents[0].length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
          }
        }
  
        var total_second_ads = 0
        var total_second_tc = 0
        var percentage_tc = 0;
        var percentage_ytn = 0;

        for (var i = 0; i < contents[0].length; i++) {
          
          if(contents[0][i].type == "video/mp4"){
            const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
            total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
          } 
          else 
          {
            total_mix_content_duration += parseInt(contents[0][i].duration)
          }

          if(ads_active[i].active){
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_ads += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_ads += parseInt(contents[0][i].duration)
            }
          }
          else
          {
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

              total_second_tc += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_second_tc += parseInt(contents[0][i].duration)
            }
          }

        }

        percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
        percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;
  
        this.setState({ percentage_tc, percentage_ytn,selectedCampaignAds: event.target.value, total_mix_content_duration })
        
      } 
      else
      {
        var total_mix_content_duration = 0;

        if(this.state.contents[0].length > 0){
          for (var i = 0; i < this.state.contents[0].length; i++) {
            if(this.state.contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
    
              total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
            }
          }

          
        this.setState({ percentage_ytn : 0, percentage_tc : 100, selectedCampaignAds: event.target.value, total_mix_content_duration })
        }

      }
      
    }

    handleToggle = value => (e) => {
      
        /*const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
          checked: newChecked,
        });*/

        console.log(value)
    };

    onSortEnd = ({oldIndex, newIndex}) => {

        var itemsArray = [[]]
        var finalItemsArray = [[]]
  
        finalItemsArray = this.state.contents
        itemsArray = arrayMove(this.state.contents[this.state.currentContainer], oldIndex, newIndex)

        finalItemsArray.map((value, index) => {
          if(index === this.state.currentContainer){
            finalItemsArray[index] = itemsArray
          }
        })

        this.setState({ contents: finalItemsArray });
    };
  
    onSortStart = ({node, index, collection, isKeySorting}) => {
    
        const allContainer = this.state.allContainer.map((value, num) => {
            
            if(num == this.state.currentContainer)
            {
            return this.state.contents[this.state.currentContainer][index].content
            } else {
            return this.state.allContainer[num]
            }
        })

        this.setState({ currentSelectedContent: index })

        if(this.state.contents[this.state.currentContainer][index].type === 'video/mp4')
        {
          if(this.state.contents[this.state.currentContainer][index].schedule){

            this.setState({ 
              selectedSecondStartDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.start_date), 
              selectedSecondEndDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.end_date),
              selectedSecondStartTime: this.state.contents[this.state.currentContainer][index].schedule.start_time,
              selectedSecondEndTime: this.state.contents[this.state.currentContainer][index].schedule.end_time,
              switchSecondChecked: true, allContainer, contentDuration: null, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          } else {
            this.setState({ 
              selectedSecondStartDate:  moment(new Date()),
              selectedSecondEndDate: moment(new Date()),
              selectedSecondStartTime: '12:00 am',
              selectedSecondEndTime: '11:59 pm',
              switchSecondChecked: false, allContainer, contentDuration: null, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          }
          
        } else {

          if(this.state.contents[this.state.currentContainer][index].schedule){
            this.setState({ 
              selectedSecondStartDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.start_date), 
              selectedSecondEndDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.end_date),
              selectedSecondStartTime: this.state.contents[this.state.currentContainer][index].schedule.start_time,
              selectedSecondEndTime: this.state.contents[this.state.currentContainer][index].schedule.end_time,
              switchSecondChecked: true, allContainer, contentDuration: this.state.contents[this.state.currentContainer][index].duration, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          } else {
            this.setState({ 
              selectedSecondStartDate:  moment(new Date()),
              selectedSecondEndDate: moment(new Date()),
              selectedSecondStartTime: '12:00 am',
              selectedSecondEndTime: '11:59 pm',
              switchSecondChecked: false, allContainer, contentDuration: this.state.contents[this.state.currentContainer][index].duration, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          }
         
        }
        
    }

    onSortTextStart = ({node, index, collection, isKeySorting}) => {
      
      this.setState({selectedTextId:index })
      
    }

    onSortTextEnd = ({oldIndex, newIndex}) => {
      this.textInput.current.focus();
      var itemsArray = []
      itemsArray = arrayMove(this.state.newText, oldIndex, newIndex)

      this.setState({ newText: itemsArray });
  };

    handleClose = () => {
        
        const currentSlideIndex = []
        if(this.state.selectedTemplate !== 'default'){
          const currentIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.selectedTemplate)
          this.state.templateCollection[currentIndex].container.map((val, index) => {
            clearTimeout(timeout[index])
            timeout[index] = null
            currentSlideIndex.push(0)
          })
        } else {
            clearTimeout(timeout[0])
            timeout[0] = null
            currentSlideIndex.push(0)
        }

        this.setState({ openDialog: false, currentSlideIndex });
    };

    deleteItem =currentIndex => () => {
      console.log(currentIndex)
    };

    handleSecondClose = () => {
      this.setState({ openSecondDialog: false });
    }

    handleItemChange = (value) => event => {

      
      if(value.selected){
        value.selected = false;
      }
      else{
        value.selected = true;
      }
      
      this.setState({ contents: this.state.contents })
    }

    renderSortableItem = () => {
      if(this.state.contents[this.state.currentContainer])
      {
        let totalDuration = 0;
        return this.state.contents[this.state.currentContainer].map((val, index)=>{
            return(
              <SortableItem handleItemChange={this.handleItemChange} 
              deleteItem={this.deleteItem} 
              currentSelectedContent={this.state.currentSelectedContent} 
              key={`item-${index}`} index={index} allindex={index} 
              value={val} handleToggle={this.handleToggle} checked={this.state.checked} mediaCollection={this.state.mediaCollection} currentContainer={this.state.currentContainer} allContainer={this.state.allContainer} />
            )
          })
      } else {
          return(
            <div></div>
          )
      }
    }

    renderMixItem = () => {

      if(this.state.selectedCampaignAds != 'none' && this.state.contents[0].length != 0){

        const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);

        var duplicate_count = 0;
        var ads_count = 0;
        var tc_count = 0;
        var total_mix_content_duration = 0;

        const contents = [[]];
        const ads_active = [];
        const allContentActive= []
        const adsContents = [];

        this.state.contents[0].map(value => { 
          if(value.schedule){
            if(moment(new Date()).isSameOrBefore(moment.unix(value.schedule.end_date)) &&
                moment(new Date()).isSameOrAfter(moment.unix(value.schedule.start_date)) 
              ) {
                if(moment(new Date()).isBefore(moment(value.schedule.end_time, "h:mm a")) &&
                  moment(new Date()).isAfter(moment(value.schedule.start_time, "hh:mm a"))
                  ) {
                  value.active = true
                } else {
                  value.active = false
                }
            } else {
              value.active = false
            }

          } else {
            value.active = true
          }
        })

        for (var i = 0; i < this.state.contents[0].length; i++) {
          if(this.state.contents[0][i].active)
          {
            allContentActive.push(this.state.contents[0][i])
          }
        }

        this.state.campaignAdsCollection[currentIndex].content[0].map(value => { 
          if(value.schedule){

            if(moment(new Date()).isSameOrBefore(moment.unix(value.schedule.end_date)) &&
                moment(new Date()).isSameOrAfter(moment.unix(value.schedule.start_date)) 
              ) {
                if(moment(new Date()).isBefore(moment(value.schedule.end_time, "h:mm a")) &&
                  moment(new Date()).isAfter(moment(value.schedule.start_time, "hh:mm a"))
                  ) {
                  value.active = true
                } else {
                  value.active = false
                }
            } else {
              value.active = false
            }

            

          } else {
            value.active = true
          }
        })

        for (var i = 0; i < this.state.campaignAdsCollection[currentIndex].content[0].length; i++) {
          if(this.state.campaignAdsCollection[currentIndex].content[0][i].active)
          {
            adsContents.push(this.state.campaignAdsCollection[currentIndex].content[0][i])
          }
        }
        
        if(adsContents.length * (this.state.selectedCampaignInterval - 1) > allContentActive.length){
  
          
          var total_content_number = adsContents.length * this.state.selectedCampaignInterval;
  
          for (var i = 0; i < total_content_number; i++) {
            
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(adsContents[ads_count]);
  
              if(ads_count > adsContents.length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
          
              ads_active.push({ active: false});

              if(allContentActive.length > 0){
                contents[0].push(allContentActive[tc_count]);
              }
              

              if(tc_count > allContentActive.length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
  
          } 
   
        } else {

          var total_content_number = allContentActive.length / (this.state.selectedCampaignInterval - 1) + allContentActive.length;
  
          for (var i = 0; i < Math.round(total_content_number); i++) {
            
            if(i == duplicate_count){
  
              ads_active.push({ active: true});
              contents[0].push(adsContents[ads_count]);
  
              if(ads_count > adsContents.length - 2){
                ads_count = 0;
              } else {
                ads_count++;
              }
  
              duplicate_count = i + this.state.selectedCampaignInterval;
            } else {
              
              ads_active.push({ active: false});

              if(allContentActive.length > 0){
                contents[0].push(allContentActive[tc_count]);
  
              }
              
              if(tc_count > allContentActive.length - 2){
                tc_count = 0;
              } else {
                tc_count++;
              }
            }
          }
        }
        
        for (var i = 0; i < contents[0].length; i++) {
          
          if(contents[0][i].type == "video/mp4"){
            const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

            total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
          } 
          else 
          {
            total_mix_content_duration += parseInt(contents[0][i].duration)
          }

        }


        let totalDuration = 0;

        if(this.state.contents[this.state.currentContainer].length > 0 ){
          return contents[0].map((value, index)=>{


            var currentIndex = this.state.mediaCollection.map(val => {
              return val.id
            }).indexOf(value.content);

            
            if(this.state.mediaCollection[currentIndex].type == "video/mp4"){

              totalDuration += parseInt(this.state.mediaCollection[currentIndex].duration);
              //console.log(value)
              //console.log(totalDuration)
              return(
                <ListItem disabled={true} style={{ opacity: ads_active[index].active ? 1 : 1, backgroundColor: ads_active[index].active ? "#d8e4ed" : null, width: '100%' }} role={undefined}  key={index} divider dense>
                  <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                    {index+1}
                  </Typography>
                  <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                  <ListItemText primary={this.state.mediaCollection[currentIndex].name} />
                </ListItem>
              )
            } else {
              
              totalDuration += parseInt(value.duration);
              //console.log(value)
              //console.log(totalDuration)
              return(
                <ListItem disabled={true} style={{ opacity: ads_active[index].active ? 1 : 1, backgroundColor: ads_active[index].active ? "#d8e4ed" : null, width: '100%' }} role={undefined}  key={index} divider dense>
                  <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                    {index+1}
                  </Typography>
                  <Avatar src={this.state.mediaCollection[currentIndex].imageUrl} >
                  </Avatar>
                  <ListItemText primary={this.state.mediaCollection[currentIndex].name} />
                </ListItem>
              )
            }
  
          })
  
          
        }
        else {
          return(
            <div></div>
          )
        }

        
        
      } else {

        const allContentActive= []

        this.state.contents[0].map(value => { 
          if(value.schedule){
            if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
              value.active = false
            } else {
              value.active = true
            }
          } else {
            value.active = true
          }
        })

        for (var i = 0; i < this.state.contents[0].length; i++) {
          if(this.state.contents[0][i].active)
          {
            allContentActive.push(this.state.contents[0][i])
          }
        }

        if(allContentActive.length > 0)
        {
          return allContentActive.map((value, index)=>{

              var currentIndex = this.state.mediaCollection.map(val => {
                return val.id
              }).indexOf(value.content);
            
            
              if(this.state.mediaCollection[currentIndex].type == "video/mp4"){
            
                return(
                  <ListItem style={{ backgroundColor: value.active ? null : "#d8e4ed", width: '100%' }} role={undefined}  key={index} divider dense>
                    <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                      {index+1}
                    </Typography>
                    <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                    <ListItemText primary={this.state.mediaCollection[currentIndex].name} />
                  </ListItem>
                )
              } else {
                return(
                  <ListItem style={{ backgroundColor: value.active ? null : "#d8e4ed", width: '100%' }} role={undefined}  key={index} divider dense>
                    <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                      {index+1}
                    </Typography>
                    <Avatar src={this.state.mediaCollection[currentIndex].imageUrl} >
                    </Avatar>
                    <ListItemText primary={this.state.mediaCollection[currentIndex].name} />
                    
                  </ListItem>
                )
              }

            })
        } else {
            return(
              <div></div>
            )
        }
      }
      
    }

    renderSortable = () => {
        return (
            <SortableContainer lockAxis="y" onSortEnd={this.onSortEnd} onSortStart={this.onSortStart}>
                <List>
                {this.renderSortableItem()}
                </List>
            </SortableContainer>
        )
    }

    renderDurationCampaign = () => {
      const formatted = moment.utc(this.state.total_mix_content_duration*1000).format('HH:mm:ss');
      var durationCampaign = "Duration of Campaign: " + formatted;

        return(durationCampaign)
    }

    renderMixContents = () => {
      return (
        <List>
        {this.renderMixItem()}
        </List>
      )
    }

    handleSelectAllStationChange = event => {

      if(this.props.editCampaign){
        if(event.target.checked){
          this.state.stationCollection.forEach(station => {
            station.campaign = this.props.editCampaignId
          })
        }
        else
        {
          this.state.stationCollection.forEach(station => {
            station.campaign = null
          })
        }
      }
      else
      {
        if(event.target.checked){
          this.state.stationCollection.forEach(station => {
            station.campaign = this.state.newPostKey
          })
        }
        else
        {
          this.state.stationCollection.forEach(station => {
            station.campaign = null
          })
        }
      }
      
     

      this.setState({ selectAllStation: event.target.checked })
    }
    
    handleStationChange = (index) => event => {

      if(this.props.editCampaign){
        const stationCollection = [...this.state.stationCollection];
        const stationCollectionNoChange = [...this.state.stationCollectionNoChange];
  
        if(stationCollection[index].campaign == this.props.editCampaignId)
        {
          if(stationCollectionNoChange[index].campaign == this.props.editCampaignId)
          {
            stationCollection[index].campaign = null;
          }
          else{
            stationCollection[index].campaign = stationCollectionNoChange[index].campaign;
          }
          
        }
        else
        {
          stationCollection[index].campaign = this.props.editCampaignId
        }
  
        this.setState({ stationCollection  })
      }

      else
      {
        const stationCollection = [...this.state.stationCollection];
        const stationCollectionNoChange = [...this.state.stationCollectionNoChange];
  
        if(stationCollection[index].campaign == this.state.newPostKey)
        {
          stationCollection[index].campaign = stationCollectionNoChange[index].campaign;
        }
        else
        {
          stationCollection[index].campaign = this.state.newPostKey
        }
  
        this.setState({ stationCollection  })
      }
      
    }

    renderStationItem = () => {
      
      if(this.props.editCampaign){

        if(firebase.auth().currentUser.email == "taysockchoo@apmasia.com.sg" 
          || firebase.auth().currentUser.email == "kymlow@apmasia.com.sg"
          || firebase.auth().currentUser.email == "zhouquan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "yongpohyi@apmasia.com.sg"
          || firebase.auth().currentUser.email == "nurulkhairiah@apmasia.com.sg"){

            /*return this.state.stationCollection.map((value, index) => {

              if(value.id === '-M1f7821il8GQiWfwq2O' || value.id === '-M1f79nU5ZbYYZVjc86D' 
              || value.id === '-MNXZ1ATJFRg82UeLgF5' || value.id === '-MNXWcVETq9_W0XozRCC' 
              || value.id === '-MNXRFTGXZwi3PE7BItY' || value.id === '-MNXP97XrPWODjcOziZ5' )
              {
                if(value.campaign == this.props.editCampaignId){
                  return(
                    <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                      <Checkbox
                        checked={true}
                        value="primary"
                        onChange={this.handleStationChange(index)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                        {index+1}
                      </Typography>
                      <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                      <ListItemText primary={value.name} />
                    </ListItem>
                  )
                }
                else{
                  return(
                    <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                      <Checkbox
                        checked={false}
                        value="primary"
                        onChange={this.handleStationChange(index)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                        {index+1}
                      </Typography>
                      <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                      <ListItemText primary={value.name} />
                    </ListItem>
                  )
                }
              }
              
              
            })*/
        }

        else

        {
          return this.state.stationCollection.map((value, index) => {

            if(value.campaign == this.props.editCampaignId){
              return(
                <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                  <Checkbox
                    checked={true}
                    value="primary"
                    onChange={this.handleStationChange(index)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                    {index+1}
                  </Typography>
                  <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                  <ListItemText primary={value.name} />
                </ListItem>
              )
            }
            else{
              return(
                <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                  <Checkbox
                    checked={false}
                    value="primary"
                    onChange={this.handleStationChange(index)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                    {index+1}
                  </Typography>
                  <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                  <ListItemText primary={value.name} />
                </ListItem>
              )
            }
            
          })
        }

        
      }
      else{
        return this.state.stationCollection.map((value, index) => {

          if(value.campaign == this.state.newPostKey){
            return(
              <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                <Checkbox
                  checked={true}
                  value="primary"
                  onChange={this.handleStationChange(index)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                  {index+1}
                </Typography>
                <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                <ListItemText primary={value.name} />
              </ListItem>
            )
          }
          else{
            return(
              <ListItem style={{ width: '100%', padding: 0 }} role={undefined} key={index} divider dense>
                <Checkbox
                  checked={false}
                  value="primary"
                  onChange={this.handleStationChange(index)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <Typography style={{ padding: 10 }} variant="caption" display="block" gutterBottom>
                  {index+1}
                </Typography>
                <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
                <ListItemText primary={value.name} />
              </ListItem>
            )
          }
          
        })
      }
      
    }

    renderStation = () => {
      return (
        <List>
        {this.renderStationItem()}
        </List>
      )
    }

    onChangeGridInput = (row, column) => event => {
      this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplate[row][column] = event.target.value

      this.setState({ gridText: event.target.value})
    }

    onFocusTextInput = event => {
      this.setState({ gridText: event.target.value, currentSelectedGrid: event.target.id })
    }

    renderGridTemplate = (selectedContentIndex) => {

      return(
        this.state.contents[this.state.currentContainer][selectedContentIndex].gridTemplate.map((value, index)=> {
          return(
            <Grid key={selectedContentIndex + "A:" + index} container spacing={0} direction="row"   style={{ width: 1080/4, backgroundColor: 'transparent' }}>
              <Grid container spacing={0} direction="row" style={{width: 107.5 }}>
                <Input
                  id={"0_" + index}
                  onFocus={this.onFocusTextInput}
                  value={value[0]}
                  margin='dense'
                  fullWidth
                  onChange={this.onChangeGridInput(index, 0)}
                  style={{ paddingTop:3,paddingLeft: 4, backgroundColor: '#222222', color: 'white', fontSize: 3.37, marginLeft: 0, marginRight: 3, height: 7.25 }}
                ></Input>
              </Grid>
              <Grid container spacing={0} direction="row" style={{width: 25 }}>
                <Input
                  id={"1_" + index}
                  onFocus={this.onFocusTextInput}
                  value={value[1]}
                  margin='dense'
                  fullWidth
                  onChange={this.onChangeGridInput(index, 1)}
                  style={{ paddingTop:3,paddingLeft: 4, backgroundColor: '#222222', color: 'white', fontSize: 3.37, marginRight: 3, height: 7.25 }}
                ></Input>
              </Grid>
              <Grid container spacing={0} direction="row" style={{width: 107.5 }}>
                <Input
                  id={"2_" + index}
                  onFocus={this.onFocusTextInput}
                  value={value[2]}
                  fullWidth
                  onChange={this.onChangeGridInput(index, 2)}
                  style={{ paddingTop:3, paddingLeft: 4, backgroundColor: '#222222', color: 'white', fontSize: 3.37, marginRight: 3, height: 7.25 }}
                ></Input>
              </Grid>
              <Grid container spacing={0} direction="row" style={{width: 25 }}>
                <Input
                  id={"3_" + index}
                  onFocus={this.onFocusTextInput}
                  value={value[3]}

                  fullWidth
                  onChange={this.onChangeGridInput(index, 3)}
                  style={{ paddingTop:1, paddingLeft: 4,backgroundColor: '#222222', color: 'white', fontSize: 3.37, marginRight: 0, height: 7.25 }}
                ></Input>
              </Grid>
            </Grid>
          )
        })

      )
    }

    renderTemplate = () => {

      if(this.state.selectedTemplate !== 'default'){
        
        const templateData = [];
        this.state.templateCollection.map((value, index) => {
          if(value.id === this.state.selectedTemplate)
          {
            value.container.map((val, index) => {
              templateData.push({ id:val.id, width: val.width, height: val.height })
            })
            
          }
        })

        const mediaData = [];
        
        this.state.allContainer.map((value, index) => { 
          
          if(this.state.allContainer[index] !== '')
          {

            this.state.mediaCollection.map((value, mediaIndex) => {
              if(value.id === this.state.allContainer[index])
              {
                
                mediaData.push({ id: value.id, name: value.name, imageUrl: value.imageUrl, type: value.type })
              }
            })

          } else {
            mediaData.push({ id: '', name: '', imageUrl: '' })
          }
          
        })
        
        return templateData.map((value, index) => {

          if(mediaData[index].type === "video/mp4")
          {
            return (
              <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} onClick={()=> { 
    
                  if(this.state.currentContainer !== index)
                  {
                    const currentContent = this.state.allContainer[index]
                    const currenIndex = this.state.contents[index].map(val => { return val.content }).indexOf(currentContent)
                    
                    if(currenIndex !== -1){

                      if(this.state.contents[index][currenIndex].schedule){
                        this.setState({ 
                          selectedSecondEndDate: moment.unix(this.state.contents[index][currenIndex].schedule.end_date),
                          selectedSecondStartDate: moment.unix(this.state.contents[index][currenIndex].schedule.start_date),
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.start_time,
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.end_time,
                          switchSecondChecked: true, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: null });
                      } else {
                        this.setState({ switchSecondChecked: false, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: null });
                      }
                      
                    } else {
                      this.setState({ currentContainer: index, checked: [] }) 
                    }
    
                  }
                  
                }} item>
                <Paper className={ this.state.currentContainer === index ? this.props.classes.container : null } square style={{height: '100%', width:'100%', display: 'flex', justifyContent: 'center'}} >
                  <video style={{ width: '100%' }}  controls  src={mediaData[index] ? mediaData[index].imageUrl : ''} type="video/mp4"></video>
                </Paper>
              </Grid> )
          } else {
            return (
              <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} onClick={()=> { 
    
                  if(this.state.currentContainer !== index)
                  {
                    const currentContent = this.state.allContainer[index]
                    const currenIndex = this.state.contents[index].map(val => { return val.content }).indexOf(currentContent)
                    
                    if(currenIndex !== -1){
                      if(this.state.contents[index][currenIndex].schedule){
                        this.setState({ 
                          selectedSecondEndDate: moment.unix(this.state.contents[index][currenIndex].schedule.end_date),
                          selectedSecondStartDate: moment.unix(this.state.contents[index][currenIndex].schedule.start_date),
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.start_time,
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.end_time,
                          switchSecondChecked: true, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: this.state.contents[index][currenIndex].duration });
                      } else {
                        this.setState({ switchSecondChecked: false, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: this.state.contents[index][currenIndex].duration });
                      }
                    } else {
                     
                      this.setState({ currentContainer: index, checked: [] }) 
                    }
    
                  }
                  
                }} item>
                <Paper className={ this.state.currentContainer === index ? this.props.classes.container : null } square style={{height: '100%', width:'100%'}} >
                  <AutoFitImage imgSize="contain" imgSrc={mediaData[index] ? mediaData[index].imageUrl : ''} style={{/*..your style here..*/}}/>
                </Paper>
              </Grid> )
          }
        })

      } else {

        const mediaData = [];
        
        this.state.allContainer.map((value, index) => { 
          
          
          if(this.state.allContainer[index] && this.state.allContainer[index] !== '')
          {
            
            this.state.mediaCollection.map((val, mediaindex) => {
              if(val.id === this.state.allContainer[index])
              {
                
                mediaData.push({ id: val.id, name: val.name, imageUrl: val.imageUrl, type: val.type })
              }
            })
          } else {
            mediaData.push({ id: '', name: '', imageUrl: '' })
          }
          
        })
       
        
        if(mediaData[0] && mediaData[0].type === "video/mp4")
        {
          return (
            <Grid item style={{ position: 'absolute' }}>
              <Paper square
                style={{ height: this.props.ads ? 608/4 : 1920/4, width:1080/4, display: 'flex', justifyContent: 'center', backgroundColor: 'transparent' }}
              >
                <video  style={{ width: '100%' }}  controls src={mediaData[0] ? mediaData[0].imageUrl : ''} type="video/mp4"></video>
              </Paper>
            </Grid>)
        } else {

          if(mediaData[0] && mediaData[0].stretch){
            return (
              <Grid item style={{ position: 'absolute' }}>
                <Paper square
                  style={{ height: this.props.ads ? 608/4 : 1920/4, width:1080/4, backgroundColor: 'transparent' }}
                >
                  <img style={{ width: '100%', height: '100%'}} src={mediaData[0] ? mediaData[0].imageUrl : ''}/>
                </Paper>
              </Grid>)
          } else {
            return (
              <Grid item style={{ position: 'absolute' }}>
                <Paper square
                  style={{ height: this.props.ads ? 608/4 : 1920/4, width:1080/4, backgroundColor: 'transparent' }}
                >
                  <AutoFitImage imgSize="contain" frameWidth={1080/4 + "px"} frameHeight={this.props.ads ? 608/4 : 1920/4 + "px"} imgSrc={mediaData[0] ? mediaData[0].imageUrl : ''} style={{/*..your style here..*/}}/>
                </Paper>
              </Grid>)
          }
        }
        
      }
      
    }

    renderDialog = () => {
        if(this.state.dialogOption === 'addMedia'){
            return(
                <div>
                    <DialogContent style={{ paddingTop: 20, paddingLeft: 0, paddingRight:0}}>
                    <CustomizedTable 
                        rows={[
                            { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
                            { id: 'imageUrl', numeric: false, disablePadding: false, label: 'File Thumbnail', sortable: true, thumbnail: true },
                        ]} 
                        orderBy={'created'}
                        folderCollection={this.state.folderCollection}
                        preview={true}
                        movefile={true}
                        select={true}
                        dataName='media'
                        folderSelection={true}
                        addToText={this.state.addToText}
                        onAddMediaClick={this.onAddMediaClick}/>
                    </DialogContent>
                </div>
            )
        } else if (this.state.dialogOption === 'preview'){

          if(this.state.selectedTemplate !== 'default'){

            const templateData = [];
            this.state.templateCollection.map((value, index) => {
              if(value.id === this.state.selectedTemplate)
              {
                value.container.map((val, index) => {
                  templateData.push({ id:val.id, width: val.width, height: val.height })
                })
              }
            })

            return(
              <DialogContent style={{ backgroundColor: '#efefef', paddingTop: 25, paddingBottom: 25 }}>
                {templateData.map((value, index) => {
                  return (
                      <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} item>
                        {this.renderListAllCarouselItem( index, value.width/4, value.height/4 )}
                      </Grid>
                    )
                })}
              </DialogContent>
            )
            
          } else {
            return (
              <DialogContent style={{ backgroundColor: '#efefef', paddingTop: 25, paddingBottom: 25 }}>
                <TransformWrapper
                  defaultScale={1}
                  defaultPositionX={200}
                  defaultPositionY={100}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <React.Fragment>
                    <div className="tools">
                      <button onClick={zoomIn}>+</button>
                      <button onClick={zoomOut}>-</button>
                      <button onClick={resetTransform}>reset</button>
                    </div>
                    <TransformComponent>
                      <Grid style={{height: 1920/4, width: 1080/4}} item>
                        <Grid style={{ zIndex: 99,  position: 'absolute', height: 1920/4, width:1080/4, backgroundColor: 'transparent',overflow: 'hidden' }}>
                          {this.renderTextPreview()}
                        </Grid>
                          {this.renderBackground()}
                        {this.renderListAllCarouselItem(0, 1080/4, 1920/4)}
                        
                      </Grid>
                    </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
                
              </DialogContent>)
          }

        }

        else if (this.state.dialogOption === 'edit'){

          return (
            <DialogContent >
              <Grid container>
                <DayPicker 
                  switchChecked = {this.state.switchSecondChecked}
                  handleSwitchChange={() => this.handleSecondSwitchChange()}
                  selectedStartTime={this.state.selectedSecondStartTime} 
                  selectedEndTime={this.state.selectedSecondEndTime} 
                  selectedStartDate={this.state.selectedSecondStartDate} 
                  selectedEndDate={this.state.selectedSecondEndDate} 
                  openDialogStartDate={() => this.openSecondDialogStartDate()} 
                  openDialogEndDate={() => this.openSecondDialogEndDate()} 
                  openDialogStartTime={() => this.openSecondDialogStartTime()} 
                  openDialogEndTime={() => this.openSecondDialogEndTime()} 
                  closeDialog={() => this.closeDialog()}/>
              </Grid>
            </DialogContent>
          )
        }
    }

    renderScheduleDialog = () => {
      if (this.state.dialogSecondOption === 'StartDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedStartDate}
                onChange={this.handleStartDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'EndDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedEndDate}
                onChange={this.handleEndDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'StartTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedStartTime}
                onChange={this.handleStartTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'EndTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedEndTime}
                onChange={this.handleEndTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondStartDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedSecondStartDate}
                onChange={this.handleSecondStartDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondEndDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedSecondEndDate}
                onChange={this.handleSecondEndDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondStartTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedSecondStartTime}
                onChange={this.handleSecondStartTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondEndTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedSecondEndTime}
                onChange={this.handleSecondEndTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }
    }

    handleStartDateSelect = date => {
      if(date.isSameOrBefore(this.state.selectedEndDate) == true)
      {
        this.setState({selectedStartDate:date, openSecondDialog: false })
      }
      
    }

    handleEndDateSelect = date => {
      if(date.isSameOrAfter(this.state.selectedStartDate) == true)
      {
        this.setState({selectedEndDate:date, openSecondDialog: false })
      }
      
    }

    handleStartTimeSelect = time => {
     
      var beginningTime = moment(time.formatted, 'h:mma');
      var endTime = moment(this.state.selectedEndTime, 'h:mma');

      if(beginningTime.isSameOrBefore(endTime) == true)
      {
        this.setState({selectedStartTime:time.formatted })
      }
      
    }

    handleEndTimeSelect = time => {

      var endTime = moment(time.formatted, 'h:mma');
      var beginningTime = moment(this.state.selectedStartTime, 'h:mma');
      if(endTime.isSameOrAfter(beginningTime) == true)
      {
        this.setState({selectedEndTime:time.formatted })
      }
      
    }

    handleSecondStartDateSelect = date => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1)
      {
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.start_date = date.unix()
      }
      
      if(date.isSameOrBefore(this.state.selectedSecondEndDate) == true)
      {
        this.setState({selectedSecondStartDate:date, openSecondDialog: false, contents: newContents })
      }
      

    }

    handleSecondEndDateSelect = date => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1)
      {
        
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.end_date = date.unix()
      }
      
      if(date.isSameOrAfter(this.state.selectedSecondEndDate) == true)
      {
        this.setState({selectedSecondEndDate:date, openSecondDialog: false, contents: newContents })
      }

    }

    handleSecondStartTimeSelect = time => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1){
        
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.start_time = time.formatted
      }
      
      var beginningTime = moment(time.formatted, 'h:mma');
      var endTime = moment(this.state.selectedSecondEndTime, 'h:mma');
      if(beginningTime.isSameOrBefore(endTime) == true)
      {
        this.setState({selectedSecondStartTime:time.formatted, contents: newContents })
      }
     

    }

    handleSecondEndTimeSelect = time => {
      
      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1){
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.end_time = time.formatted
      }
      
      var endTime = moment(time.formatted, 'h:mma');
      var beginningTime = moment(this.state.selectedSecondStartTime, 'h:mma');
      if(endTime.isSameOrAfter(beginningTime) == true)
      {
        this.setState({selectedSecondEndTime:time.formatted, contents: newContents })

      }
      
    }


    renderListAllCarouselItem = (cIndex, itemWidth, itemHeight) => {
        return this.state.contents[cIndex].map((value, index) => { 

          const currentIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(value.content)

          if(value.transition === 'none'){
            return <Fade key={index} timeout={0} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              <Grid style={{ paddingTop: 35, position: 'absolute'  }}>
              {this.state.contents[cIndex][index].gridTemplateActive != null
              && this.state.contents[cIndex][index].gridTemplateActive == true?
              this.state.currentSlideIndex[cIndex] === index ? 
                      this.renderGridTemplate(index) : null : null}
                  </Grid>
              </Paper>
            </Fade>
          } else if(value.transition === 'fade'){
            return <Fade key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              <Grid style={{ paddingTop: 35, position: 'absolute'  }}>
              {this.state.contents[cIndex][index].gridTemplateActive != null
              && this.state.contents[cIndex][index].gridTemplateActive == true?
              this.state.currentSlideIndex[cIndex] === index ? 
                      this.renderGridTemplate(index) : null : null}
                  </Grid>
              </Paper>
            </Fade>
          } else if(value.transition === 'zoom'){
            return <Zoom key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              <Grid style={{ paddingTop: 35, position: 'absolute'  }}>
              {this.state.contents[cIndex][index].gridTemplateActive != null
              && this.state.contents[cIndex][index].gridTemplateActive == true?
              this.state.currentSlideIndex[cIndex] === index ? 
                      this.renderGridTemplate(index) : null : null}
                  </Grid>
              </Paper>
            </Zoom>
          } 
        })
    }

    onAddMediaClick = selected => {

      if(this.state.addToText){

        if(this.state.addToBackground){
          const currentMediaIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(selected)

          this.setState({ backgroundImage: this.state.mediaCollection[currentMediaIndex].imageUrl, 
            backgroundImageName: this.state.mediaCollection[currentMediaIndex].name,
            backgroundImageId: this.state.mediaCollection[currentMediaIndex].id,
            backgroundActive: true,
            openDialog: false })
        }
        else
        {
          const currentMediaIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(selected)

          var _newText = this.state.newText;
          
          if(_newText.length > 0){
            _newText[this.state.selectedTextId].backgroundImage = this.state.mediaCollection[currentMediaIndex].imageUrl;
            _newText[this.state.selectedTextId].backgroundImageId = this.state.mediaCollection[currentMediaIndex].id;
            _newText[this.state.selectedTextId].backgroundImageName = this.state.mediaCollection[currentMediaIndex].name;
            _newText[this.state.selectedTextId].backgroundImageType = this.state.mediaCollection[currentMediaIndex].type;
            _newText[this.state.selectedTextId].backgroundActive = true;
    
            this.setState({ newText: _newText, openDialog: false })
          }
        }

      }
      else
      {
        const newContents = [...this.state.contents]
        selected.map((value, index) => {
          const currentIndex = newContents[this.state.currentContainer].map(val => { return val.content }).indexOf(value)
          const currentMediaIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(value)
  
          if(this.state.mediaCollection[currentMediaIndex].type === 'video/mp4')
            {
              newContents[this.state.currentContainer].push({ schedule: null, duration: null, transition: 'fade', content: value, type: this.state.mediaCollection[currentMediaIndex].type })
            } else {
              newContents[this.state.currentContainer].push({ schedule: null, duration: 10, transition: 'fade', content: value, type: this.state.mediaCollection[currentMediaIndex].type })
            }
        })
  
        var total_mix_content_duration = 0;
        var total_second_ads = 0
        var total_second_tc = 0
        var percentage_tc = 0;
        var percentage_ytn = 0;
  
        if(this.state.selectedCampaignAds != 'none'){
          const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);
  
          var duplicate_count = 0;
          var ads_count = 0;
          var tc_count = 0;
          
  
          const contents = [[]];
          const ads_active = [];
    
          if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > newContents[0].length){
    
            var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
    
            for (var i = 0; i < total_content_number; i++) {
              
              if(i == duplicate_count){
    
                ads_active.push({ active: true});
                contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
    
                if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                  ads_count = 0;
                } else {
                  ads_count++;
                }
    
                duplicate_count = i + this.state.selectedCampaignInterval;
              } else {
                
                ads_active.push({ active: false});
                contents[0].push(newContents[0][tc_count]);
    
  
                if(tc_count > newContents[0].length - 2){
                  tc_count = 0;
                } else {
                  tc_count++;
                }
              }
    
            } 
     
          } else {
            var total_content_number = newContents[0].length / (this.state.selectedCampaignInterval-1) + newContents[0].length;
    
            for (var i = 0; i < Math.round(total_content_number); i++) {
              
              if(i == duplicate_count){
    
                ads_active.push({ active: true});
                contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
    
                if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                  ads_count = 0;
                } else {
                  ads_count++;
                }
    
                duplicate_count = i + this.state.selectedCampaignInterval;
              } else {
                
                ads_active.push({ active: false});
                contents[0].push(newContents[0][tc_count]);
    
                if(tc_count > newContents[0].length - 2){
                  tc_count = 0;
                } else {
                  tc_count++;
                }
              }
            }
          }
  
          
    
          for (var i = 0; i < contents[0].length; i++) {
            if(contents[0][i].type == "video/mp4"){
              const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
    
              total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
            } 
            else 
            {
              total_mix_content_duration += parseInt(contents[0][i].duration)
            }
  
            if(ads_active[i].active){
              if(contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
                total_second_ads += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_second_ads += parseInt(contents[0][i].duration)
              }
            }
            else
            {
              if(contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
  
                total_second_tc += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_second_tc += parseInt(contents[0][i].duration)
              }
            
            }
  
          }
  
          percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
          percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;
  
        }
        else
        {
          if(this.state.contents[0].length > 0){
            for (var i = 0; i < this.state.contents[0].length; i++) {
              if(this.state.contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
      
                total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
              }
            }
          }
  
          percentage_tc = 100;
          percentage_ytn = 0;
        }
        
        if(this.state.selectedTemplate !== 'default'){
          const allContainer = []
          this.state.templateCollection.map((value, index) => {
            if(value.id === this.state.selectedTemplate)
            {
              value.container.map((val, index) => {
                if(this.state.allContainer[index] == '' && index == this.state.currentContainer)
                {
                  allContainer.push(newContents[this.state.currentContainer][0].content)
                } else {
                  allContainer.push(this.state.allContainer[index])
                }
              })
              
            }
          })
  
          this.setState({ percentage_tc, percentage_ytn, total_mix_content_duration, contents: newContents, openDialog: false, allContainer, contentDuration: newContents[this.state.currentContainer][0].duration, transitionSelected: newContents[this.state.currentContainer][0].transition })
        } else {
          const allContainer = []
          allContainer.push(newContents[this.state.currentContainer][0].content)
  
          this.setState({ percentage_tc, percentage_ytn, total_mix_content_duration, contents: newContents, openDialog: false, allContainer, contentDuration: newContents[this.state.currentContainer][0].duration, transitionSelected: newContents[this.state.currentContainer][0].transition })
        }
  
      }
      
    }

    handleCampaignNameChange = event => {
      this.setState({ campaignName: event.target.value });
    };  

    handleSaveCampaign = () => {
      
      if(this.state.selectedTemplate !== 'default' )
      {
        let templateRef = directoryMode[0].mode ? app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref('template/' + this.state.selectedTemplate) : app.database().ref('template/' + this.state.selectedTemplate);

        templateRef.once('value', snapshot => {
          const item = snapshot.val();

          var scheduleContent = {
            start_date: this.state.selectedStartDate.unix() ,
            end_date: this.state.selectedEndDate.unix(),
            start_time: this.state.selectedStartTime,
            end_time: this.state.selectedEndTime
          }
  
          var postData = {
            name: this.state.campaignName == ''? 'default' : this.state.campaignName,
            created: this.props.editCampaign ? this.state.created : firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            content: this.state.contents,
            ads_content: this.state.selectedCampaignAds == 'none' ? null : this.state.selectedCampaignAds,
            template: this.state.selectedTemplate,
            template_name: item.name,
            schedule: this.state.switchChecked ? scheduleContent : null,
            change: true,
          };
      
          // Get a key for a new Post.
          if(this.props.editCampaign){

            // Write the new post's data simultaneously in the posts list and the user's post list.
            var updates = {};

            if(this.props.ads){
              updates['/campaign_ads/' + this.state.currentCampaignId] = postData;
            } else {
              updates['/campaign_tc/' + this.state.currentCampaignId] = postData;
            }

            if(directoryMode[0].mode){

              app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().update(updates);
            }
            else
            {
              app.database().ref().update(updates);
            }
            

            
          } else {

            var newPostKey = this.props.ads ? firebase.database().ref().child('campaign_ads').push().key : firebase.database().ref().child('campaign_tc').push().key;
      
            // Write the new post's data simultaneously in the posts list and the user's post list.
            var updates = {};

            if(this.props.ads){
              updates['/campaign_ads/' + newPostKey] = postData;
            } else {
              updates['/campaign_tc/' + newPostKey] = postData;
            }
            
            if(directoryMode[0].mode){

              app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().update(updates);
            }
            else
            {
              app.database().ref().update(updates);
            }
          }
            
        });
      } else {


        var scheduleContent = {
          start_date: this.state.selectedStartDate.unix() ,
          end_date: this.state.selectedEndDate.unix(),
          start_time: this.state.selectedStartTime,
          end_time: this.state.selectedEndTime
        }

        const contents = [[]];

        if(this.state.selectedCampaignAds != 'none' && this.state.contents[0].length != 0){

            const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id }).indexOf(this.state.selectedCampaignAds);

            var duplicate_count = 0;
            var ads_count = 0;
            var tc_count = 0;
            var total_mix_content_duration = 0;
    
            const ads_active = [];
            const allContentActive= []
    
            this.state.contents[0].map(value => { 
              if(value.schedule){
                if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
                  value.active = false
                } else {
                  value.active = true
                }
              } else {
                value.active = true
              }
            })
    
            for (var i = 0; i < this.state.contents[0].length; i++) {
              if(this.state.contents[0][i].active)
              {
                allContentActive.push(this.state.contents[0][i])
              }
            }
      
            if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval - 1) > allContentActive.length){
      
              var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
      
              for (var i = 0; i < total_content_number; i++) {
                
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(allContentActive[tc_count]);
    
                  if(tc_count > allContentActive.length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
      
              } 
      
            } else {
    
              var total_content_number = allContentActive.length / (this.state.selectedCampaignInterval - 1) + allContentActive.length;
      
              for (var i = 0; i < Math.round(total_content_number); i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(allContentActive[tc_count]);
      
                  if(tc_count > allContentActive.length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
              }
            }

        }

        var postData = {
          name: this.state.campaignName == ''? 'default' : this.state.campaignName,
          created: this.props.editCampaign ? this.state.created : firebase.database.ServerValue.TIMESTAMP,
          updated: firebase.database.ServerValue.TIMESTAMP,
          content: this.state.selectedCampaignAds != 'none' && this.state.contents[0].length != 0 ? contents : this.state.contents,
          ads_content: this.state.selectedCampaignAds == 'none' ? null : this.state.selectedCampaignAds,
          template: this.state.selectedTemplate,
          template_name: 'default',
          schedule: this.state.switchChecked ? scheduleContent : null,
          ads_interval: this.state.selectedCampaignInterval,
          text: this.state.newText.length > 0 ? this.state.newText : null,
          background_image_name: this.state.backgroundImageName,
          background_image: this.state.backgroundImage,
          background_active: this.state.backgroundImageActive,
          background_image_color: this.state.backgroundImageColor,
          grid_template: this.state.gridTemplateActive ? this.state.gridTemplate : null,
          grid_template_active: this.state.gridTemplateActive,
        };

        var postDatatc = {
          name: this.state.campaignName == ''? 'default' : this.state.campaignName,
          created: this.props.editCampaign ? this.state.created : firebase.database.ServerValue.TIMESTAMP,
          updated: firebase.database.ServerValue.TIMESTAMP,
          content: this.state.contents,
          ads_content: this.state.selectedCampaignAds == 'none' ? null : this.state.selectedCampaignAds,
          template: this.state.selectedTemplate,
          template_name: 'default',
          schedule: this.state.switchChecked ? scheduleContent : null,
          ads_interval: this.state.selectedCampaignInterval,
          change: true,
          text: this.state.newText.length > 0 ? this.state.newText : null,
          background_image_name: this.state.backgroundImageName,
          background_image: this.state.backgroundImage,
          background_active: this.state.backgroundImageActive,
          background_image_color: this.state.backgroundImageColor,
          grid_template: this.state.gridTemplateActive ? this.state.gridTemplate : null,
          grid_template_active: this.state.gridTemplateActive,
        };
    
        if(!this.props.ads){
          var updatesfull = {};
          updatesfull['/campaign/' + (this.props.editCampaign ? this.state.currentCampaignId : this.state.newPostKey) ] = postData;

          if(directoryMode[0].mode){

            app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().update(updatesfull);
          }
          else
          {
            app.database().ref().update(updatesfull);
          }
        }
       
    
        // Get a key for a new Post.
        if(this.props.editCampaign){
          
          // Write the new post's data simultaneously in the posts list and the user's post list.
          var updates = {};
          
          if(this.props.ads){
            updates['/campaign_ads/' + this.state.currentCampaignId] = postData;
          } else {
            updates['/campaign_tc/' + this.state.currentCampaignId] = postDatatc;
          }
          
          if(directoryMode[0].mode){

            app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().update(updates);
          }
          else
          {
            app.database().ref().update(updates);
          }

          for (var i = 0; i < this.state.stationCollection.length; i++)
          {
            if(this.state.stationCollection[i].campaign != this.state.stationCollectionNoChange[i].campaign){
              
              if(directoryMode[0].mode)
              {
                app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref("station/" + this.state.stationCollection[i].id).update({ campaign: this.state.stationCollection[i].campaign })
              }
              else
              {
                app.database().ref("station/" + this.state.stationCollection[i].id).update({ campaign: this.state.stationCollection[i].campaign })
              }
              
            }
          }
          
        } else {

          // Write the new post's data simultaneously in the posts list and the user's post list.
          var updates = {};
          if(this.props.ads){
            updates['/campaign_ads/' + this.state.newPostKey] = postData;
          } else {
            updates['/campaign_tc/' + this.state.newPostKey] = postDatatc;
          }

          if(directoryMode[0].mode){

            app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref().update(updates);
          }
          else
          {
            app.database().ref().update(updates);
          }

          for (var i = 0; i < this.state.stationCollection.length; i++)
          {
            if(this.state.stationCollection[i].campaign != this.state.stationCollectionNoChange[i].campaign){
              
              if(directoryMode[0].mode)
              {
                app.database("https://suntec-f5bcf-44a5b.firebaseio.com/").ref("station/" + this.state.stationCollection[i].id).update({ campaign: this.state.stationCollection[i].campaign })
              }
              else
              {
                app.database().ref("station/" + this.state.stationCollection[i].id).update({ campaign: this.state.stationCollection[i].campaign })
              }
              
            }
          }
        }
      }
      this.props.handleTabsChangeIndex(0)
      
      this.setState({ selectedTemplate:'default', contents: [[]], campaignName: '', allContainer: [], currentContainer: 0, transitionSelected: 'fade', contentDuration: 0, currentSlideIndex: [0] })
    }

    onEditSortableListItem = () => {
      this.setState({ 
        openDialog: true, 
        dialogOption: 'edit', 
        dialogTitle: 'Edit File',
        fullScreenDialog: false, 
        dialogTitleVisible: true
      })
    }

    handleContentDurationChange = name => event => {

        const newContents = [...this.state.contents]
        var total_mix_content_duration = 0;
        var total_second_ads = 0
        var total_second_tc = 0
        var percentage_tc = 0;
        var percentage_ytn = 0;

        if(event.target.value > 0 ){

          if(this.state.selectedCampaignAds != "none"){
            newContents[this.state.currentContainer][this.state.currentSelectedContent].duration = event.target.value

            const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);
            var duplicate_count = 0;
            var ads_count = 0;
            var tc_count = 0;

            const contents = [[]];
            const ads_active = [];
      
            if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > newContents[0].length){
      
              var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
      
              for (var i = 0; i < total_content_number; i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(newContents[0][tc_count]);
      
  
                  if(tc_count > newContents[0].length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
      
              } 
      
            } else {
              var total_content_number = newContents[0].length / (this.state.selectedCampaignInterval-1) + newContents[0].length;
      
              for (var i = 0; i < Math.round(total_content_number); i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(newContents[0][tc_count]);
      
                  if(tc_count > newContents[0].length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
              }
            }

            for (var i = 0; i < contents[0].length; i++) {
              if(contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
      
                total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_mix_content_duration += parseInt(contents[0][i].duration)
              }

              if(ads_active[i].active){
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_ads += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_ads += parseInt(contents[0][i].duration)
                }
              }
              else
              {
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_tc += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_tc += parseInt(contents[0][i].duration)
                }
              
              }

            }

            percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
            percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;
            
          }
          else
          {
            newContents[this.state.currentContainer][this.state.currentSelectedContent].duration = event.target.value

            if(this.state.contents[0].length > 0){
              for (var i = 0; i < this.state.contents[0].length; i++) {
                if(this.state.contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
        
                  total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
                }
              }
            }
            percentage_ytn = 0;
            percentage_tc = 100;
          }

          this.setState({ [name]: event.target.value, contents: newContents, total_mix_content_duration, percentage_ytn, percentage_tc });
         
        } else {

          if(this.state.selectedCampaignAds != "none"){
            newContents[this.state.currentContainer][this.state.currentSelectedContent].duration = 0

            const currentIndex = this.state.campaignAdsCollection.map(value => { return value.id}).indexOf(this.state.selectedCampaignAds);
            var duplicate_count = 0;
            var ads_count = 0;
            var tc_count = 0;
            var total_mix_content_duration = 0;
  
            const contents = [[]];
            const ads_active = [];
      
            if(this.state.campaignAdsCollection[currentIndex].content[0].length * (this.state.selectedCampaignInterval-1) > newContents[0].length){
      
              var total_content_number = this.state.campaignAdsCollection[currentIndex].content[0].length * this.state.selectedCampaignInterval;
      
              for (var i = 0; i < total_content_number; i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(newContents[0][tc_count]);
      
  
                  if(tc_count > newContents[0].length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
      
              } 
      
            } else {
              var total_content_number = newContents[0].length / (this.state.selectedCampaignInterval-1) + newContents[0].length;
      
              for (var i = 0; i < Math.round(total_content_number); i++) {
                
                if(i == duplicate_count){
      
                  ads_active.push({ active: true});
                  contents[0].push(this.state.campaignAdsCollection[currentIndex].content[0][ads_count]);
      
                  if(ads_count > this.state.campaignAdsCollection[currentIndex].content[0].length - 2){
                    ads_count = 0;
                  } else {
                    ads_count++;
                  }
      
                  duplicate_count = i + this.state.selectedCampaignInterval;
                } else {
                  
                  ads_active.push({ active: false});
                  contents[0].push(newContents[0][tc_count]);
      
                  if(tc_count > newContents[0].length - 2){
                    tc_count = 0;
                  } else {
                    tc_count++;
                  }
                }
              }
            }
  
            var total_second_ads = 0
            var total_second_tc = 0
            var percentage_tc = 0;
            var percentage_ytn = 0;
      
            for (var i = 0; i < contents[0].length; i++) {
              if(contents[0][i].type == "video/mp4"){
                const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);
      
                total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
              } 
              else 
              {
                total_mix_content_duration += parseInt(contents[0][i].duration)
              }

              if(ads_active[i].active){
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_ads += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_ads += parseInt(contents[0][i].duration)
                }
              }
              else
              {
                if(contents[0][i].type == "video/mp4"){
                  const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(contents[0][i].content);

                  total_second_tc += this.state.mediaCollection[currentIndex2].duration;
                } 
                else 
                {
                  total_second_tc += parseInt(contents[0][i].duration)
                }
              
              }

            }

            percentage_tc = (total_second_tc * 100) /  total_mix_content_duration;
            percentage_ytn = (total_second_ads * 100) /  total_mix_content_duration;
            
            this.setState({ [name]: 0 , contents: newContents, total_mix_content_duration,percentage_tc, percentage_ytn });
          }
          else
          {
              newContents[this.state.currentContainer][this.state.currentSelectedContent].duration = 0

              var total_mix_content_duration = 0;
              var percentage_tc = 100;
              var percentage_ytn = 0;

              if(this.state.contents[0].length > 0){
                for (var i = 0; i < this.state.contents[0].length; i++) {
                  if(this.state.contents[0][i].type == "video/mp4"){
                    const currentIndex2 = this.state.mediaCollection.map(value => { return value.id}).indexOf(this.state.contents[0][i].content);
          
                    total_mix_content_duration += this.state.mediaCollection[currentIndex2].duration;
                  } 
                  else 
                  {
                    total_mix_content_duration += parseInt(this.state.contents[0][i].duration)
                  }
                }
              }
                
                this.setState({ [name]: event.target.value, contents: newContents, total_mix_content_duration, percentage_tc, percentage_ytn });
            }
          }

    }

    handleTransitionChange = event => {
      const currentContent = this.state.allContainer[this.state.currentContainer]
      const currenIndex = this.state.contents[this.state.currentContainer].map(val => { return val.content }).indexOf(currentContent)
      
      if(currenIndex !== -1){
        this.state.contents[this.state.currentContainer][currenIndex].transition = event.target.value
        this.setState({ transitionSelected: event.target.value });
      } else {
        this.setState({ transitionSelected: event.target.value });
      }

    }

    handleStretchChange = event => {

      const currentContent = this.state.allContainer[this.state.currentContainer]
      const currenIndex = this.state.contents[this.state.currentContainer].map(val => { return val.content }).indexOf(currentContent)
      
      if(currenIndex !== -1){
        this.state.contents[this.state.currentContainer][currenIndex].stretch = event.target.checked
        this.setState({ stretchSelected: event.target.checked });
      } else {
        this.setState({ stretchSelected: event.target.checked });
      }

    }
    
    renderTransition(){

      return(
        <Grid container>
          <Grid item xs={8}>
            <TextField
              disabled={this.state.contents[this.state.currentContainer].length > 0 && this.state.contentDuration !== null? false:true}
              fullWidth 
              id="outlined-number"
              label="Duration"
              value={this.state.contentDuration === null ? 0 : this.state.contentDuration}
              InputProps={{ inputProps: { min: 0, max: 3600 } }}
              onChange={this.handleContentDurationChange('contentDuration')}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4} style={{ paddingLeft: 10, marginTop: 10 }}>
              <Typography style={{ fontSize: 12 }}>Grid Template</Typography>
              <Checkbox
                checked={this.state.contents[this.state.currentContainer].length > 0 ?  
                  this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplateActive != undefined &&  
                  this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplateActive == true ? 
                  true : false
                  : false}
                onChange={this.handleGridTemplateChange}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
          </Grid>
        </Grid>
      )
    }

    onPreviewClick = () => {
      this.setState({ 
        openDialog: true, 
        dialogOption: 'preview', 
        dialogTitle: 'Preview',
        fullScreenDialog: false, 
        dialogTitleVisible: false
      })
      
      this.shiftSlide()
    }

    toggleSlide = (index) => event =>{
      
      
      if(this.state.currentSlideIndex[index] < this.state.contents[index].length - 1){
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = newCurrentSlide[index] + 1
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopSlide(index)
        }
      } else {
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = 0
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopSlide(index)
        }
      }
     
    } 

    toggleNextSlide = (index) => {
      
      
      if(this.state.currentSlideIndex[index] < this.state.contents[index].length - 1){
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = newCurrentSlide[index] + 1
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopNextSlide(index, nextSlideIndex)
        }
      } else {
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = 0
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopNextSlide(index, nextSlideIndex)
        }
      }
     
    } 

    loopNextSlide = (index, nextSlideIndex) => {
      timeout[index] = this.props.setTimeout(this.toggleSlide(index, this.state.contents[index].length), this.state.contents[index][nextSlideIndex].duration * 1000)
    }

    loopSlide = (index) => {
      timeout[index] = this.props.setTimeout(this.toggleSlide(index, this.state.contents[index].length), this.state.contents[index][this.state.currentSlideIndex[index]].duration * 1000)
    }

    shiftSlide = () => {

      if(this.state.selectedTemplate !== 'default')
      {
        const currentIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.selectedTemplate)
        this.state.templateCollection[currentIndex].container.map((val, index) => {
          if(this.state.contents[index].length > 1){
            if(this.state.contents[index][0].type !== 'video/mp4')
            {
              timeout[index] = this.props.setTimeout(this.toggleSlide(index), this.state.contents[index][this.state.currentSlideIndex[index]].duration * 1000)
            } 
          }
        })

      } else {
        if( this.state.contents[0].length > 1 && this.state.contents[0][0].type !== 'video/mp4'){
          timeout[0] = this.props.setTimeout(this.toggleSlide(0), this.state.contents[0][this.state.currentSlideIndex[0]].duration * 1000)
        }
      }
      
    }  

    onClearClick = () => {
      const contents = []
      const allContainer = []
      this.state.contents.map(val => {
        contents.push([])
        allContainer.push('')
      })

      this.setState({ newText: [], contents, campaignName: '', allContainer, currentContainer: 0, transitionSelected: 'fade', contentDuration: 0, currentSlideIndex: [0] })
    }

    disablePreview = () => {

      let checkedContent = true

      this.state.contents.map(val=> {
        
        if(val.length > 0){
          checkedContent = false
        }

      })

      if(this.state.newText.length > 0){
        checkedContent = false
      }

      if(this.state.gridTemplateActive == true){
        checkedContent = false
      }

      return checkedContent
    }

    openDialogStartDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'StartDate', 
        dialogSecondTitle: 'Start Date'
      })
    }

    openDialogEndDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'EndDate', 
        dialogSecondTitle: 'End Date'
      })
    }

    openDialogStartTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'StartTime', 
        dialogSecondTitle: 'Start Time'
      })
    }

    openDialogEndTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'EndTime', 
        dialogSecondTitle: 'End Time'
      })
    }

    openSecondDialogStartDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondStartDate', 
        dialogSecondTitle: 'Start Date'
      })
    }

    openSecondDialogEndDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondEndDate', 
        dialogSecondTitle: 'End Date'
      })
    }

    openSecondDialogStartTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondStartTime', 
        dialogSecondTitle: 'Start Time'
      })
    }

    openSecondDialogEndTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondEndTime', 
        dialogSecondTitle: 'End Time'
      })
    }

    closeDialog = () => {
      this.setState({ 
        openSecondDialog: false,
      })
    }

    handleSwitchChange = () => {
      this.setState(state => ({ switchChecked: !state.switchChecked }));
    }

    handleSecondSwitchChange = () => {

      if(!this.state.switchSecondChecked)
      {
        const newContents = [...this.state.contents];
        var scheduleContent = {
          start_date: this.state.selectedSecondStartDate.unix(),
          end_date: this.state.selectedSecondEndDate.unix(),
          start_time: this.state.selectedSecondStartTime,
          end_time: this.state.selectedSecondEndTime,
        }
        
        if(this.state.currentSelectedContent !== -1)
        {
          newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule = scheduleContent
        }
        
        this.setState(state => ({ switchSecondChecked: !state.switchSecondChecked, contents: newContents }));

      } else {
        const newContents = [...this.state.contents];
        if(this.state.currentSelectedContent !== -1)
        {
          newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule = null
        }
        

        this.setState(state => ({ switchSecondChecked: !state.switchSecondChecked, contents: newContents }));
      }
      
    }

    onRemoveSortableListItem = () => {
      const newContents = [...this.state.contents];
      const new_contents = []

      newContents[this.state.currentContainer].forEach((value, index) => {

        if(value.selected == false){
          new_contents.push(value)
        }
      });

      newContents[this.state.currentContainer] = new_contents;

      this.setState({  contents: newContents, allContainer: [''] , currentSelectedContent: 0})
      
    }

    onAddText = () => {

      

      var new_text = {
          x: 0,
          y: 0,
          paddingLeft: 10,
          paddingRight: 10, 
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 12,
          color: '#000000',
          backgroundColor: '#FFFFFF',
          fontFamily: 'Arial',
          bold: false,
          text: "text" + this.state.newText.length,
          width: 1080,
          height: 200,
          backgroundImage: null,
          backgroundActive: false,
          backgroundImageId: null,
          backgroundImageType: null,
          backgroundImageName: 'Container Image/Video',
          backgroundTransparent: true,
          uid: this.state.textUid,
          borderColor: '#000000',
          borderWidth: 1,
          noborder: true,
          rotateAngle:0,
      }

      this.state.textUid++;

      this.setState(prevState => ({ newText: [ new_text, ...prevState.newText], selectedTextId: 0 }))

      this.textInput.current.focus();
    }

    handleTextResize = (style, isShiftKey, type) => {
      let { top, left, width, height } = style
     
      width = Math.round(width * 4)
      height = Math.round(height * 4)

      var _newText = this.state.newText;

      top = Math.round(top)
      left = Math.round(left)

        _newText[this.state.selectedTextId].width = width;
        _newText[this.state.selectedTextId].height = height;
        //_newText[this.state.selectedTextId].x = left;
        //_newText[this.state.selectedTextId].y = top;

        this.setState({
          newText :  _newText
        })
     
    }

    handleTextDrag = (deltaX, deltaY) => {
      var _newText = this.state.newText;

      var _x = _newText[this.state.selectedTextId].x / 4 + deltaX;
      var _y = _newText[this.state.selectedTextId].y / 4 + deltaY;

      _newText[this.state.selectedTextId].x = _x * 4;
      _newText[this.state.selectedTextId].y = _y * 4;

      this.setState({
        newText :  _newText
      })
    }

    renderBackground(){

      if(this.state.backgroundImage && this.state.backgroundImageActive){
        return(
          <Grid style={{ position: 'absolute', height: 1920/4, width:1080/4, backgroundColor: this.state.backgroundImageColor,overflow: 'hidden' }}>
             <img style={{ width: '100%', height: '100%'}} src={this.state.backgroundImage ? this.state.backgroundImage : ''}/>
          </Grid>
        )
      }
      else
      {
        return(
        <Grid style={{ position: 'absolute', height: 1920/4, width:1080/4, backgroundColor: this.state.backgroundImageColor,overflow: 'hidden' }}>
             
        </Grid>)
      }

    }


    onClickTextBox = (index) =>{
      this.textInput.current.focus();
      this.setState({selectedTextId:index })
    }

    renderText(){

      
      if(this.state.newText.length > 0){
        return this.state.newText.map((value, index) => { 

          var paddingLeft = value.paddingLeft + 'px';
          var paddingRight = value.paddingRight + 'px';
          var paddingTop = value.paddingTop + 'px';
          var paddingBottom = value.paddingBottom + 'px';
          var width = value.width/4
          var height = value.height/4
          var margin_Left = (value.x / 4);
          var margin_Top = (value.y / 4);
          var rotateAngle = value.rotateAngle;

          return(
            <Grid onClick={()=>this.onClickTextBox(index)} key={"textbox" + index} style={{  transform: `rotate(${rotateAngle}deg)` ,marginLeft: margin_Left, marginTop: margin_Top, zIndex:  this.state.newText.length - index , position: 'absolute', height: height, width:width, backgroundColor: 'transparent',overflow: 'hidden' }}>
              
              {this.state.newText[index].backgroundImage && this.state.newText[index].backgroundImageType == "video/mp4" ?
                <Grid style={{ position:'absolute', width: width, height: height, backgroundColor: 'transparent'}}>
                  <video autoPlay style={{ width: '100%' }}  controls  src={this.state.newText[index].backgroundImage ? this.state.newText[index].backgroundImage : ''} type="video/mp4"></video>
                </Grid>
                : null
                }
              <InputBase 
                value={value.text}  multiline style={{ 
                backgroundPosition: 'top', 
                backgroundRepeat: 'no-repeat', 
                backgroundSize: 'cover', 
                fontFamily:value.fontFamily,  
                backgroundImage: this.state.newText[index].backgroundImage && this.state.newText[index].backgroundImageType == "image/jpeg" ? `url(${this.state.newText[index].backgroundImage})` : null,
                width, 
                height,  
                paddingLeft, 
                paddingRight, 
                paddingTop, 
                paddingBottom, 
                backgroundColor: 
                value.backgroundTransparent ? 'transparent' : value.backgroundColor, 
                color: value.color, 
                fontWeight: value.bold ? 'bold': 'normal',
                border: value.noborder ? '0px solid #222' : value.borderWidth + 'px solid ' + value.borderColor,
                fontSize: value.fontSize }}></InputBase>
                

                {this.state.selectedTextId == index ? <ResizableRect
                
                  left={0}
                  top={0}
                  width={width}
                  height={height}
                  rotateAngle={0}
                  zoomable='n, w, s, e, nw, ne, se, sw'
                  onResize={this.handleTextResize}
                  onDrag={this.handleTextDrag}
                /> : null}
                
            </Grid>
          )
  
        })
      }
    }

    renderTextPreview(){

      
      if(this.state.newText.length > 0){
        return this.state.newText.map((value, index) => { 

          var paddingLeft = value.paddingLeft + 'px';
          var paddingRight = value.paddingRight + 'px';
          var paddingTop = value.paddingTop + 'px';
          var paddingBottom = value.paddingBottom + 'px';
          var width = value.width/4
          var height = value.height/4
          var margin_Left = value.x / 4;
          var margin_Top = value.y / 4;

          return(
            <Grid onClick={()=>this.onClickTextBox(index)} key={"textbox" + index} style={{ marginLeft: margin_Left, marginTop: margin_Top, zIndex:  this.state.newText.length - index , position: 'absolute', height: height, width:width, backgroundColor: 'transparent',overflow: 'hidden' }}>
              
              {this.state.newText[index].backgroundImage && this.state.newText[index].backgroundImageType == "video/mp4" ?
                <Grid style={{ position:'absolute', width: width, height: height, backgroundColor: 'transparent'}}>
                  <video autoPlay style={{ width: '100%' }}  controls  src={this.state.newText[index].backgroundImage ? this.state.newText[index].backgroundImage : ''} type="video/mp4"></video>
                </Grid>
                : null
                }
              <InputBase 
                value={value.text}  multiline style={{ 
                backgroundPosition: 'top', 
                backgroundRepeat: 'no-repeat', 
                backgroundSize: 'cover', 
                fontFamily:value.fontFamily,  
                backgroundImage: this.state.newText[index].backgroundImage && this.state.newText[index].backgroundImageType == "image/jpeg" ? `url(${this.state.newText[index].backgroundImage})` : null,
                width, 
                height,  
                paddingLeft, 
                paddingRight, 
                paddingTop, 
                paddingBottom, 
                backgroundColor: 
                value.backgroundTransparent ? 'transparent' : value.backgroundColor, 
                color: value.color, 
                fontWeight: value.bold ? 'bold': 'normal',
                border: value.noborder ? '0px solid #222' : value.borderWidth + 'px solid ' + value.borderColor,
                fontSize: value.fontSize }}></InputBase>
              
                
            </Grid>
          )
  
        })
      }
    }

    onClickTextList = index => {
      console.log(index)
      this.setState({selectedTextId:index })
    }

    onDeleteTextList = () => {
      var _newText = this.state.newText;
      _newText.splice(this.state.selectedTextId, 1);
      this.setState({ newText: _newText, selectedTextId: 0 })
    }

    onTextSequence = index => {

      
      if(index != 0){
  
        var lastindex = index - 1;
        var b = this.state.newText[index];
        this.state.newText[index] = this.state.newText[lastindex];
        this.state.newText[lastindex] = b;

        this.setState({ newText: this.state.newText })
      }

    }

    onTextDownSequence = index => {

      
      if(index != this.state.newText.length -1 ){
  
        var lastindex = index + 1;
        var b = this.state.newText[index];
        this.state.newText[index] = this.state.newText[lastindex];
        this.state.newText[lastindex] = b;

        this.setState({ newText: this.state.newText })
      }

    }

    renderTextList(){
      if(this.state.newText.length > 0){
        return this.state.newText.map((value, index) => { 

          return(
            <SortableTextItem value={value} currentIndex={index} 
              selectedTextId={this.state.selectedTextId} 
              index={index}
            />
          )
  
        })
      }
    }

    onChangeBoldText =() =>{

      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].bold = !this.state.newText[this.state.selectedTextId].bold;

      this.setState({ newText: _newText })
    }

    onChangePaddingLeft = event => {
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].paddingLeft = event.target.value;

      this.setState({ newText: _newText })
    }

    onChangePaddingRight = event => {
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].paddingRight = event.target.value;

      this.setState({ newText: _newText })
    }

    onChangePaddingTop = event => {
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].paddingTop = event.target.value;

      this.setState({ newText: _newText })
    }

    onChangePaddingBottom = event => {
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].paddingBottom = event.target.value;

      this.setState({ newText: _newText })
    }

    onChangeRotateAngle = event => {
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].rotateAngle = event.target.value;

      this.setState({ newText: _newText })
    }

    onChangePositionX = event => {
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].x = event.target.value;

      this.setState({ newText: _newText })
    }

    onChangePositionY = event => {
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].y = event.target.value;

      this.setState({ newText: _newText })
    }

    handleChangeFontSize = event => {
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].fontSize = event.target.value;

      this.setState({ newText: _newText })
    }

    setColor = event => {
      var _newText = this.state.newText;

      if(_newText.length > 0){
        _newText[this.state.selectedTextId].color = event.hex;

        this.setState({ newText: _newText })
      }
      
    }
    
    setBorderColor = event => {
      var _newText = this.state.newText;

      if(_newText.length > 0){
        _newText[this.state.selectedTextId].borderColor = event.hex;

        this.setState({ newText: _newText })
      }
      
    }

    handleBorderChange = event => {
      
      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].noborder = event.target.checked;
      this.setState({ newText: _newText })
      
    }

    setBackgroundColor = event => {
      var _newText = this.state.newText;

      if(_newText.length > 0){
        _newText[this.state.selectedTextId].backgroundColor = event.hex;

        this.setState({ newText: _newText })
      }
      
    }

    setDefaultBackgroundColor = event => {
      this.setState({ backgroundImageColor: event.hex })
    }

    onTextChange = event => {
      var _newText = this.state.newText;

      if(_newText.length > 0){
        _newText[this.state.selectedTextId].text = event.target.value;

        this.setState({ newText: _newText })
      }
    };  

    onChangeWidth = event => {
      var _newText = this.state.newText;

      if(event.target.value > 1080){
        event.target.value = 1080
      }

      if(_newText.length > 0 ){
        _newText[this.state.selectedTextId].width = event.target.value;

        this.setState({ newText: _newText })
      }
    }

    onChangeBorderWidth = event => {
      var _newText = this.state.newText;

      if(event.target.value > 10){
        event.target.value = 10
      }

      if(_newText.length > 0 ){
        _newText[this.state.selectedTextId].borderWidth = event.target.value;

        this.setState({ newText: _newText })
      }
    }

    onChangeHeight = event => {
      var _newText = this.state.newText;

      if(event.target.value > 1920){
        event.target.value = 1920
      }

      if(_newText.length > 0 ){
        _newText[this.state.selectedTextId].height = event.target.value;

        this.setState({ newText: _newText })
      }
    }

    onChangeFont = event => {

      var _newText = this.state.newText;

      if(_newText.length > 0 ){
        _newText[this.state.selectedTextId].fontFamily = event.target.value;
        this.setState({ selectedFont: event.target.value, newText: _newText })
      }
      
    }

    handleGridTemplateChange = event => {
      this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplateActive = event.target.checked;
      this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplate = [
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
        ["","","",""],
      ];

      this.setState({ contents: this.state.contents })
    }

    handleTransparentBGChange = event => {

      var _newText = this.state.newText;
      _newText[this.state.selectedTextId].backgroundTransparent = event.target.checked;
      this.setState({ _newText })
    }

    handleBackgroundImageChange = event => {
      this.setState({ backgroundImageActive: event.target.checked })
    }

    handleFullScreen = (resetTransform) => event => {

      /*resetTransform.dispatchEvent(
        new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
        })
      )*/
      console.log(resetTransform);
      
      if(this.state.gridTemplateFullscreen){

        this.setState({ gridTemplateFullscreen: false, transformClick: true })
       
      }
      else
      {

        this.setState({ gridTemplateFullscreen: true, transformClick: true })

       
      }
      
    }

    insertLine = event => {
      var newGridTemplate = [];
      var grid_template_content = this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplate;

      for (var i = 0; i < 51; i++) {
        newGridTemplate.push(["","","",""])
      }

      var a = 0;
      var b = 0;
      var c = 0;
      var d = 0;
      for (var i = 0; i < 51; i++) {

        

        if(i.toString() == this.state.currentSelectedGrid.split('_')[1] && this.state.currentSelectedGrid.split('_')[0] == "0"){

          a = a + 1;
         
        }

        if(i.toString() == this.state.currentSelectedGrid.split('_')[1] && this.state.currentSelectedGrid.split('_')[0] == "1"){

          b = b + 1;
         
        }

        if(i.toString() == this.state.currentSelectedGrid.split('_')[1] && this.state.currentSelectedGrid.split('_')[0] == "2"){

          c = c + 1;
         
        }

        if(i.toString() == this.state.currentSelectedGrid.split('_')[1] && this.state.currentSelectedGrid.split('_')[0] == "3"){

          d = d + 1;
         
        }
        
       
        if(a < 51){
          newGridTemplate[a][0] = grid_template_content[i][0];
        }

        if(b < 51){
          newGridTemplate[b][1] = grid_template_content[i][1];
        }

        if(c < 51){
          newGridTemplate[c][2] = grid_template_content[i][2];
        }

        if(d < 51){
          newGridTemplate[d][3] = grid_template_content[i][3];
        }
        

        a++;
        b++;
        c++;
        d++;
      }

      this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplate = newGridTemplate;


      this.setState({ contents: this.state.contents})
    }

    deleteLine = event => {
      var newGridTemplate = [];
      var grid_template_content = this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplate;

      for (var i = 0; i < 51; i++) {
        newGridTemplate.push(["","","",""])
      }

      var a = 0;
      var b = 0;
      var c = 0;
      var d = 0;
      for (var i = 0; i < 51; i++) {

        

        if(i.toString() == this.state.currentSelectedGrid.split('_')[1] && this.state.currentSelectedGrid.split('_')[0] == "0"){

          a = a + 1;
         
        }

        if(i.toString() == this.state.currentSelectedGrid.split('_')[1] && this.state.currentSelectedGrid.split('_')[0] == "1"){

          b = b + 1;
         
        }

        if(i.toString() == this.state.currentSelectedGrid.split('_')[1] && this.state.currentSelectedGrid.split('_')[0] == "2"){

          c = c + 1;
         
        }

        if(i.toString() == this.state.currentSelectedGrid.split('_')[1] && this.state.currentSelectedGrid.split('_')[0] == "3"){

          d = d + 1;
         
        }
        
       
        if(a < 51 && a > -1){
          newGridTemplate[i][0] = grid_template_content[a][0];
        }

        if(b < 51 && b > -1){
          newGridTemplate[i][1] = grid_template_content[b][1];
        }

        if(c < 51 && c > -1){
          newGridTemplate[i][2] = grid_template_content[c][2];
        }

        if(d < 51 && d > -1){
          newGridTemplate[i][3] = grid_template_content[d][3];
        }
        

        a++;
        b++;
        c++;
        d++;
      }

      this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplate = newGridTemplate;


      this.setState({ contents: this.state.contents})
    }

    render() {

        const { classes } = this.props;
        return (
          <div className={classes.root}>
            <Dialog
                onClose={this.handleSecondClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.openSecondDialog}
                maxWidth='xl'
            >
              <Tooltip title="Close">
                <Button  onClick={() => this.handleSecondClose() }>
              
                    <CloseIcon fontSize="small" />
                  
                </Button>
            </Tooltip>
            <DialogTitle id="customized-dialog-title" onClose={this.handleSecondClose}>
                {this.state.dialogSecondTitle}
            </DialogTitle>
            
            {this.renderScheduleDialog()}
            </Dialog>
            <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.openDialog}
                maxWidth='xl'
                fullScreen={this.state.fullScreenDialog}
            >
              <Tooltip title="Close">
                <Button  onClick={() => this.handleClose() }>
              
                    <CloseIcon fontSize="small" />
                  
                </Button>
            </Tooltip>
              {this.state.dialogTitleVisible && !this.state.fullScreenDialog ? 
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                {this.state.dialogTitle}
            </DialogTitle> : <div></div>}

            {this.state.fullScreenDialog ? 
            <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography style={{ color:'white' }} variant="h6" className={classes.title}>
                {this.state.dialogTitle}
              </Typography>
            </Toolbar>
          </AppBar> : <div></div>}
            
            {this.renderDialog()}
            </Dialog>
            <Grid style={{ minHeight:windowHeight }} container justify='flex-start' direction={this.state.isMobile ? "column" : "row"}>
                <Grid style={{ display: this.state.gridTemplateFullscreen ? 'none' : null, position: "relative", width: this.state.isMobile ? window.innerWidth : 350 , height: '100%', padding: 10 }}>
                    
                    <Grid container>
                      <Grid container>
                        <Grid item xs={6} style={{ paddingRight: 5 }}>
                          <Button fullWidth style={{ marginTop: 10 }} variant="outlined" color="primary" onClick={()=> this.setState({ openDialog: true, fullScreenDialog: true, dialogOption: 'addMedia', dialogTitle: 'Add Media', dialogTitleVisible: true, addToText: false }) }>
                          Add File
                          </Button>
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 5, paddingRight: 5 }}>
                          <Button disabled={this.state.contents[this.state.currentContainer].length > 0 ? false : true } fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onEditSortableListItem() }>
                          Edit
                          </Button>
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 5  }}>
                          <Button disabled={this.state.contents[this.state.currentContainer].map(val => { return val.selected }).indexOf(true) != -1 ? false : true } fullWidth style={{ marginTop: 10 }} variant="outlined" color="secondary" 
                          onClick={()=> this.onRemoveSortableListItem() }>
                          Delete
                          </Button>
                        </Grid>
                        {this.renderTransition()}
                        
                      </Grid>
                      <Grid style={{ position: 'relative', overflowY: 'scroll', height: this.state.isMobile ? '100%' : windowHeight-140, width: this.state.isMobile ? window.innerWidth - 100 : 350 }} container>
                          {this.renderSortable()}
                      </Grid>
                    </Grid>
                    
                </Grid>
                {this.props.ads ? null : <Grid style={{ display: this.state.gridTemplateFullscreen ? 'none' : null, backgroundColor: '#efefef', overflowY: 'scroll', position: "relative", width: this.state.isMobile ? window.innerWidth : 350 , height: windowHeight, padding: 10 }}>
                    
                    <Grid container style={{ marginTop: 10 }} >
                      <Grid item xs={9} style={{ paddingLeft: 10 }}>
                        <Button style={{ height: '100%' }} fullWidth variant="outlined" color="default" onClick={()=> this.onAddText()}>
                          + Add Container
                        </Button>
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <Button style={{ height: '100%' }} fullWidth variant="outlined" color="default" onClick={()=> this.onDeleteTextList()}>
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10,  paddingLeft: 10  }} >
                        <Typography noWrap={false}  display="inline" style={{ fontSize: 14, paddingBottom: 10  }}>
                          {this.state.contents[this.state.currentContainer].length > 0 ? 
                            this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplateActive != null &&  
                            this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplateActive == true ? 
                            this.state.gridText : ''
                            : null}
                        </Typography>
                        <ListItem style={{ width: '100%', padding: 0 }} role={undefined}  divider dense>
                        </ListItem>
                    </Grid>
                    
                    <Grid container >
                      <Grid item xs={12} style={{ paddingLeft: 10 }}>
                        <TextField
                          inputRef={this.textInput} 
                          disabled={this.state.newText.length > 0 ? false : true }
                          fullWidth
                          multiline
                          rows={2}
                          margin="dense"
                          id="standard-name"
                          label="Your Text"
                          value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].text ? this.state.newText[this.state.selectedTextId].text : ''}
                          onChange={this.onTextChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10 }} >
                      <Grid item xs={8} style={{ paddingLeft: 10 }}>
                        <Button disabled={this.state.newText.length > 0 ? false : true } style={{ height: '100%' }} fullWidth variant="outlined" color="default" 
                        onClick={()=>this.setState({ openDialog: true, fullScreenDialog: true, dialogOption: 'addMedia', dialogTitle: 'Add Media', dialogTitleVisible: true, addToText: true, addToBackground: false })}>
                          <Typography noWrap  display="inline">
                           {this.state.newText.length > 0 ? this.state.newText[this.state.selectedTextId].backgroundImageName : "Image/Video"}
                            </Typography>
                        </Button>
                      </Grid>
                      
                    </Grid>
                    <Grid container style={{ marginTop: 10 }} >
                          <Grid item xs={3} style={{ paddingLeft: 10 }}>
                              <Button disabled={this.state.newText.length > 0 ? false : true } style={{ height: 56, backgroundColor: this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].bold ? 'gray': 'transparent' }} fullWidth variant="outlined" color="default" onClick={()=> this.onChangeBoldText()}>
                                BOLD
                              </Button>
                            </Grid>
                          <Grid item xs={5} style={{ paddingLeft: 10 }}>
                              <Select
                               disabled={this.state.newText.length > 0 ? false : true }
                                    value={this.state.newText.length > 0 ? this.state.newText[this.state.selectedTextId].fontFamily : 'Arial'}
                                    onChange={this.onChangeFont}
                                    input={
                                    <OutlinedInput
                                        fullWidth
                                        name="fonts"
                                        id="outlined-age-simple"
                                    />
                                    }
                                >
                                      <MenuItem value={'Garamond'} >
                                        <em>Garamond</em>
                                      </MenuItem>
                                      <MenuItem value={"Arial"}>
                                        <em>Arial</em>
                                      </MenuItem>
                                      <MenuItem value={"Cambria"}>
                                        <em>Cambria</em>
                                      </MenuItem>
                                      <MenuItem value={'Helvetica'}>
                                        <em>Helvetica</em>
                                      </MenuItem>
                                      <MenuItem value={'Tahoma'}>
                                        <em>Tahoma</em>
                                      </MenuItem>
                                      <MenuItem value={'Prestige Elite Std'}>
                                        <em>Prestige Elite Std</em>
                                      </MenuItem>
                                </Select>
                          </Grid>
                          <Grid item xs={4} style={{ paddingLeft: 10, paddingBottom: 8,  alignSelf:"flex-end" }}>
                                  <Select
                                        disabled={this.state.newText.length > 0 ? false : true }
                                        value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].fontSize ? this.state.newText[this.state.selectedTextId].fontSize : 12}
                                        onChange={this.handleChangeFontSize}
                                        input={
                                        <OutlinedInput
                                            fullWidth
                                            name="folder"
                                            id="outlined-age-simple"
                                        />
                                        }
                                    >
                                          <MenuItem value={10}>
                                            <em>10px</em>
                                          </MenuItem>
                                          <MenuItem value={12}>
                                            <em>12px</em>
                                          </MenuItem>
                                          <MenuItem value={18}>
                                            <em>18px</em>
                                          </MenuItem>
                                          <MenuItem value={24}>
                                            <em>24px</em>
                                          </MenuItem>
                                          <MenuItem value={36}>
                                            <em>36px</em>
                                          </MenuItem>
                                          <MenuItem value={48}>
                                            <em>48px</em>
                                          </MenuItem>
                                    </Select>
                          </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10 }} >
                        <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <TextField
                              disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Margin Left"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].paddingLeft ? this.state.newText[this.state.selectedTextId].paddingLeft : 0}
                              InputProps={{ inputProps: { min: 0, max: 3600 } }}
                              onChange={this.onChangePaddingLeft}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Margin Right"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].paddingRight ? this.state.newText[this.state.selectedTextId].paddingRight : 0}
                              InputProps={{ inputProps: { min: 0, max: 3600 } }}
                              onChange={this.onChangePaddingRight}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Margin Top"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].paddingTop ? this.state.newText[this.state.selectedTextId].paddingTop : 0}
                              InputProps={{ inputProps: { min: 0, max: 3600 } }}
                              onChange={this.onChangePaddingTop}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Margin Bottom"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].paddingBottom ? this.state.newText[this.state.selectedTextId].paddingBottom : 0}
                              InputProps={{ inputProps: { min: 0, max: 3600 } }}
                              onChange={this.onChangePaddingBottom}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                        </Grid>
                        
                    </Grid>
                    <Grid container style={{ paddingLeft: 10, marginTop: 10 }} >
                      <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Rotate Angle"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].rotateAngle ? this.state.newText[this.state.selectedTextId].rotateAngle : 0}
                              InputProps={{ inputProps: { min: 0, max: 360 } }}
                              onChange={this.onChangeRotateAngle}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                    </Grid>
                    <Grid container style={{ marginTop: 10 }} >
                      <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Position X"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].x ? this.state.newText[this.state.selectedTextId].x : 0}
                              InputProps={{ inputProps: { min: 0, max: 3600 } }}
                              onChange={this.onChangePositionX}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Position Y"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].y ? this.state.newText[this.state.selectedTextId].y : 0}
                              InputProps={{ inputProps: { min: 0, max: 3600 } }}
                              onChange={this.onChangePositionY}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Height"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].height ? this.state.newText[this.state.selectedTextId].height : 0}
                              InputProps={{ inputProps: { min: 0, max: 1920 } }}
                              onChange={this.onChangeHeight}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: 10 }}>
                        <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Width"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].width ? this.state.newText[this.state.selectedTextId].width : 0}
                              InputProps={{ inputProps: { min: 0, max: 1080 } }}
                              onChange={this.onChangeWidth}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10 }}>
                      <Grid item xs={4} style={{ paddingLeft: 10 }}>
                          <Typography style={{ fontSize: 12 }}>No Border</Typography>
                          <Checkbox
                            disabled={this.state.newText.length > 0 ? false : true }
                            checked={this.state.newText.length > 0 ? this.state.newText[this.state.selectedTextId].noborder : true}
                            onChange={this.handleBorderChange}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                      </Grid>
                      <Grid item xs={3} style={{ paddingLeft: 10, alignItems: 'center', justifyContent: 'center' }}>
                          <Typography style={{ fontSize: 12 }}>Border</Typography>
                          <Typography style={{ fontSize: 12 }}>Color</Typography>
                          <Button disabled={this.state.newText.length > 0 ? false : true } style={{ backgroundColor: "transparent", color: 'white', paddingLeft: 0, marginLeft: 0 }} variant="solid" color="default">
                          <InputColor
                              initialValue={this.state.newText.length > 0 ? this.state.newText[this.state.selectedTextId].borderColor : '#000000'}
                              onChange={this.setBorderColor}
                              placement="left"
                            />
                          </Button>
                        </Grid>
                        <Grid item xs={5} style={{ paddingLeft: 10 }}>
                          <TextField
                            disabled={this.state.newText.length > 0 ? false : true }
                              fullWidth 
                              id="outlined-number"
                              label="Border Width"
                              value={this.state.newText.length > 0 && this.state.newText[this.state.selectedTextId].borderWidth ? this.state.newText[this.state.selectedTextId].borderWidth : 0}
                              InputProps={{ inputProps: { min: 0, max: 10 } }}
                              onChange={this.onChangeBorderWidth}
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              margin="normal"
                              variant="outlined"
                            />
                      </Grid>
                   </Grid>
                    <Grid container style={{ marginTop: 10 }} >
                        <Grid item xs={4} style={{ paddingLeft: 10, alignItems: 'center', justifyContent: 'center' }}>
                          <Typography style={{ fontSize: 12 }}>Text</Typography>
                          <Typography style={{ fontSize: 12 }}>Color</Typography>
                          <Button disabled={this.state.newText.length > 0 ? false : true } style={{ backgroundColor: "transparent", color: 'white', paddingLeft: 0, marginLeft: 0 }} variant="solid" color="default">
                          <InputColor
                              initialValue={this.state.newText.length > 0 ? this.state.newText[this.state.selectedTextId].color : '#000000'}
                              onChange={this.setColor}
                              placement="left"
                            />
                          </Button>
                        </Grid>

                        <Grid item xs={4} style={{ paddingLeft: 10 }}>
                          <Typography style={{ fontSize: 12, width: 70 }}>Background Color</Typography>
                          <Button disabled={this.state.newText.length > 0 ? false : true } style={{ backgroundColor: "transparent", paddingLeft: 0, marginLeft: 0 }} variant="solid" color="default" >
                            <InputColor
                              initialValue={this.state.newText.length > 0 ? this.state.newText[this.state.selectedTextId].backgroundColor : '#000000'}
                              onChange={this.setBackgroundColor}
                              placement="left"
                            />
                          </Button>
                        </Grid>
                        <Grid item xs={4} style={{ paddingLeft: 10 }}>
                          <Typography style={{ fontSize: 12 }}>Background Transparent</Typography>
                          <Checkbox
                            disabled={this.state.newText.length > 0 ? false : true }
                            checked={this.state.newText.length > 0 ? this.state.newText[this.state.selectedTextId].backgroundTransparent : true}
                            onChange={this.handleTransparentBGChange}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: 10, marginBottom: 15 }} >
                      <Grid item xs={6} style={{ paddingLeft: 10 }}>
                        <Typography style={{ fontSize: 12 }}>Default Background Color</Typography>
                        <Button style={{ backgroundColor: "transparent", paddingLeft: 0, marginLeft: 0 }} variant="solid" color="default" >
                          <InputColor
                            initialValue={this.state.backgroundImageColor}
                            onChange={this.setDefaultBackgroundColor}
                            placement="left"
                          />
                        </Button>
                      </Grid>
                      <Grid item xs={6} style={{ paddingLeft: 10 }}>
                        <Typography style={{ fontSize: 12 }}>Active Background Image</Typography>
                        <Checkbox
                            checked={this.state.backgroundImageActive}
                            onChange={this.handleBackgroundImageChange}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                      </Grid>
                      
                      
                    </Grid>
                    <Grid style={{ paddingLeft: 10 }}>
                        <Button disabled={!this.state.backgroundImageActive} style={{ height: '100%' }} fullWidth variant="outlined" color="default" onClick={()=> this.setState({ openDialog: true, fullScreenDialog: true, dialogOption: 'addMedia', dialogTitle: 'Add Media', dialogTitleVisible: true, addToText: true, addToBackground: true }) }>
                          <Typography noWrap display="inline">
                            {this.state.backgroundImageName}
                          </Typography>
                        </Button>
                      </Grid>

                      <SortableContainer lockAxis="y"  onSortStart={this.onSortTextStart} onSortEnd={this.onSortTextEnd}>
                        <List>
                          {this.renderTextList()}
                        </List>
                      </SortableContainer>
                    
                </Grid>
                }
                <Grid  xs={this.state.gridTemplateFullscreen ? 12 : null}  container alignItems="center" justify="center" style={{ padding: 0, position: "relative", width: this.state.isMobile ? window.innerWidth : 350 , backgroundColor: '#dcdcdc' , height: windowHeight, padding: 10}} >
                  <TransformWrapper
                    //scale={this.state.transformClick ? this.state.gridTemplateFullscreen ? 3 : 1 : undefined}
                    //positionY={this.state.gridTemplateFullscreen ?  0 : undefined}
                    //positionX={this.state.gridTemplateFullscreen ? -window.innerWidth + (window.innerWidth/8) : 0 }
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <Grid style={{ diplay: 'flex', justifyContent: 'center'}}>
                      <Grid xs={12} style={{  padding: 0, display: 'flex', justifyContent: 'center',marginBottom: 10, }}>
                        <button onClick={zoomIn}  onMouseUp={()=> this.setState({ transformClick: false })}>+</button>
                        <button onClick={zoomOut} onMouseUp={()=> this.setState({ transformClick: false })}>-</button>
                        <button onClick={resetTransform} onMouseUp={()=> this.setState({ transformClick: false })}>reset</button>
                        <button onClick={resetTransform} onMouseUp={this.handleFullScreen(resetTransform)}>{this.state.gridTemplateFullscreen ? 'normal': 'fullscreen'}</button>
                        
                      </Grid>
                      {this.state.contents[this.state.currentContainer][this.state.currentSelectedContent] != null && this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplateActive == true ? <Grid xs={12} style={{  padding: 0, display: 'flex', justifyContent: 'center',marginBottom: 10, }}>
                        <button onClick={this.insertLine}>Insert Line</button>
                        <button onClick={this.deleteLine}>Delete Line</button>
                      </Grid> : null }
                      <TransformComponent>
                          <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start',
                          height: this.state.gridTemplateFullscreen?  this.state.isMobile ? "100%" : windowHeight - 100 : "100%",
                           width: this.state.gridTemplateFullscreen?  this.state.isMobile ? "100%" : window.innerWidth -250 : "100%" }}>

                          
                              <Grid container spacing={0} direction="column" alignItems="center" justify="flex-start" style={{ 
                                height: this.props.ads ? 608/4 : 1920/4, 
                                width: 1080/4, 
                                backgroundColor: '#262626', marginBottom: 10, marginTop: 10 }} >
                              {this.props.ads ? null: <Grid style={{ zIndex: 99,  position: 'absolute', 
                              height: this.props.ads ? 608/4 : 1920/4, 
                              width: 1080/4, backgroundColor: 'transparent',overflow: 'hidden' }}>
                                {this.renderText()}
                                </Grid>}
                                {this.props.ads ? null: this.renderBackground()}
                                {this.renderTemplate()}
                                <Grid style={{ marginTop: 72, zIndex: 99  }}>
                                {this.state.contents[this.state.currentContainer].length > 0 ? 
                                this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplateActive != null &&  
                                this.state.contents[this.state.currentContainer][this.state.currentSelectedContent].gridTemplateActive == true ? 
                                this.renderGridTemplate(this.state.currentSelectedContent) : null 
                                : null}
                                </Grid>
                                
                              </Grid>
                              </Grid>
                      </TransformComponent>
                      </Grid>
                    )}
                                </TransformWrapper>

                </Grid>
                
                <Grid style={{ display: this.state.gridTemplateFullscreen ? 'none' : null, position: "relative", width: this.state.isMobile ? 
                window.innerWidth : 350 , height: windowHeight, padding: 10 }}>
                  <Grid container>
                    <Grid style={{ paddingRight: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="secondary" onClick={()=> this.handleSaveCampaign()}>
                      Save
                      </Button>
                    </Grid>
                    <Grid style={{ paddingLeft: 5, paddingRight: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onPreviewClick()}>
                      Preview
                      </Button>
                    </Grid>
                    <Grid style={{ paddingLeft: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onClearClick()}>
                      Clear
                      </Button>
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="standard-name"
                    label="Campaign Name"
                    value={this.state.campaignName}
                    onChange={this.handleCampaignNameChange}
                  />
                  <Grid container>
                    <DayPicker 
                      switchChecked = {this.state.switchChecked}
                      handleSwitchChange={() => this.handleSwitchChange()}
                      selectedStartTime={this.state.selectedStartTime} 
                      selectedEndTime={this.state.selectedEndTime} 
                      selectedStartDate={this.state.selectedStartDate} 
                      selectedEndDate={this.state.selectedEndDate} 
                      openDialogStartDate={() => this.openDialogStartDate()} 
                      openDialogEndDate={() => this.openDialogEndDate()} 
                      openDialogStartTime={() => this.openDialogStartTime()} 
                      openDialogEndTime={() => this.openDialogEndTime()} 
                      closeDialog={() => this.closeDialog()}/>
                  </Grid>
                  {firebase.auth().currentUser.email == "taysockchoo@apmasia.com.sg" 
          || firebase.auth().currentUser.email == "kymlow@apmasia.com.sg"
          || firebase.auth().currentUser.email == "zhouquan@apmasia.com.sg"
          || firebase.auth().currentUser.email == "yongpohyi@apmasia.com.sg"
          || firebase.auth().currentUser.email == "nurulkhairiah@apmasia.com.sg" ? null : <div>

                  
                  {!this.props.ads ? <Typography  variant="caption" gutterBottom style={{ marginTop: 15, display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                    Assigned Stations
                  </Typography> : <div></div>}
                  {!this.props.ads ? <ListItem style={{ width: '100%', padding: 0 }} role={undefined} dense>
                    <Checkbox
                      checked={this.state.selectAllStation}
                      onChange={this.handleSelectAllStationChange}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <ListItemText primary={"Select All"} /> 
                  </ListItem>: <div></div>}
                  {!this.props.ads ? <Grid style={{ marginTop: 5, position: 'relative', overflowY: 'scroll', height: this.state.isMobile ? '100%' : windowHeight-340, width: this.state.isMobile ? window.innerWidth-20: 350 }} container>
                  {firebase.auth().currentUser.o == "youtv-3b886.firebaseapp.com" ? null : this.renderStation() }
                  </Grid> : <div></div>}

                  </div>}
                </Grid>
                
            </Grid>
          </div>
        );
    }
}

CustomizedAddCampaign.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default compose(
  withStyles(styles),
  ReactTimeout
)(CustomizedAddCampaign);
  